import React from 'react';
import { FC } from 'react';
import { useGet } from '../../hooks/useApi';
import { tenantApi } from '../../APIs/tenants';
import { reformat } from '../../utils/DateUtils';
import { comma } from '../../utils/FormUtils';

type Prop = {};
export const TenantStorageStatus: FC<Prop> = () => {
    const { data: status } = useGet(tenantApi.get.getTenantStatus);
    if (!status) {
        return <></>;
    }
    return (
        <>
            <ul className="tenant-status-list">
                <li>
                    <b>ストレージ使用量:</b> {comma(status?.data.size)} GB
                </li>
                <li>
                    <b>最大サイズ:</b>{' '}
                    {comma(status?.data.availableStorageSize)} GB
                </li>
                <li>
                    <b>使用率:</b> {status?.data.usage}%
                </li>
                <li>
                    <b>取得日:</b> {reformat(status.data.finishedAt)}
                </li>
            </ul>
        </>
    );
};
