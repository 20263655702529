import classNames from 'classnames';
import React from 'react';
import { IconName, CssStyleClass } from '@fortawesome/fontawesome-common-types';

export type IconTypeStr = `${CssStyleClass} fa-${IconName}`;
export interface IconTypeObj {
    style?: CssStyleClass;
    name: IconName;
}
export type IconType = IconTypeStr | IconTypeObj;

export interface IconProps {
    icon: IconType;
    className?: string;
    children?: React.ReactNode | undefined;
    solid?: boolean;
    regular?: boolean;
}

export function parseIconType(icon: IconTypeStr): IconTypeObj {
    const parts = /^(fa-[^ ]+) fa-(.+)$/.exec(icon);
    if (!parts) {
        return {
            name: 'question',
        };
    }
    return {
        name: parts[2]! as IconName,
        style: parts[1] as CssStyleClass,
    };
}

export const Icon = (props: IconProps) => {
    const { icon, className, solid, regular, children } = props;
    const { name: iconName, style } =
        typeof icon === 'string' ? parseIconType(icon) : icon;
    return icon ? (
        <i
            className={classNames(
                'atomic icon',
                `${
                    regular
                        ? 'fa-regular'
                        : solid
                        ? 'fa-solid'
                        : style ?? 'fa-regular'
                } fa-${iconName}`,
                className
            )}
            {...[children]}
        />
    ) : null;
};

function constIcons<T extends { [key: string]: IconTypeObj }>(input: T): T {
    return input;
}

export const Icons = constIcons({
    Add: { style: 'fa-solid', name: 'add' },
    Left: { style: 'fa-solid', name: 'chevron-left' },
    Return: { style: 'fa-solid', name: 'chevron-up' },
    Right: { style: 'fa-solid', name: 'chevron-right' },
    ReelNavigate: { style: 'fa-solid', name: 'up-down-left-right' },
    ReelEdit: { style: 'fa-solid', name: 'arrow-pointer' },
    ReelSelect: { style: 'fa-solid', name: 'crop-simple' },
    ZoomIn: { style: 'fa-solid', name: 'magnifying-glass-plus' },
    ZoomOut: { style: 'fa-solid', name: 'magnifying-glass-minus' },
    Folder: { style: 'fa-regular', name: 'folder' },
    File: { style: 'fa-regular', name: 'file' },
    Video: { style: 'fa-solid', name: 'video' },
    Delete: { style: 'fa-solid', name: 'trash-can' },
    Edit: { style: 'fa-solid', name: 'pen' },
    Export: { style: 'fa-solid', name: 'download' },
    Tag: { style: 'fa-solid', name: 'tag' },
    User: { style: 'fa-solid', name: 'user' },
    Admin: { style: 'fa-solid', name: 'user-tie' },
    Search: { style: 'fa-solid', name: 'magnifying-glass' },
    Map: { style: 'fa-solid', name: 'map-location-dot' },
    FolderOpen: { style: 'fa-solid', name: 'folder-open' },
    FolderClosed: { style: 'fa-solid', name: 'folder-closed' },
    RunAi: { style: 'fa-solid', name: 'robot' },
    Maximize: { style: 'fa-solid', name: 'maximize' },
    Project: { style: 'fa-solid', name: 'briefcase' },
    Warning: { style: 'fa-solid', name: 'triangle-exclamation' },
    UpdatePending: { style: 'fa-solid', name: 'rotate' },
    Cancel: { style: 'fa-solid', name: 'cancel' },
    Config: { style: 'fa-solid', name: 'gear' },
    Center: { style: 'fa-solid', name: 'crosshairs' },
    Spinner: { style: 'fa-solid', name: 'spinner' },
    Link: { style: 'fa-solid', name: 'link' },
    Report: { style: 'fa-solid', name: 'clipboard-list' },
    Copy: { style: 'fa-regular', name: 'copy' },
    Paste: { style: 'fa-regular', name: 'paste' },
    Eye: { style: 'fa-solid', name: 'eye' },
    EyeSlash: { style: 'fa-solid', name: 'eye-slash' },
    History: { style: 'fa-solid', name: 'history' },
    Undo: { style: 'fa-solid', name: 'undo' },
    TextFile: { style: 'fa-regular', name: 'file-text' },
    CsvFile: { style: 'fa-solid', name: 'file-csv' },
    PdfFile: { style: 'fa-regular', name: 'file-pdf' },
    ZipFile: { style: 'fa-regular', name: 'file-archive' },
    CodeFile: { style: 'fa-regular', name: 'file-code' },
    ImageFile: { style: 'fa-regular', name: 'file-image' },
    VideoFile: { style: 'fa-regular', name: 'file-video' },
    Users: { style: 'fa-solid', name: 'users' },
} as const);

export type FixedIconProps = Omit<IconProps, 'icon'>;

export const ZoomInIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.ZoomIn} {...props} />
);

export const ZoomOutIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.ZoomOut} {...props} />
);

export const FolderIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.Folder} {...props} />
);

export const FileIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.File} {...props} />
);

export const VideoFileIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.VideoFile} {...props} />
);

export const VideoIcon = (props: FixedIconProps) => (
    // <Icon iconStyle='fa-solid' icon="file-video" {...props} />
    <Icon icon={Icons.Video} {...props} />
);

export const DeleteIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.Delete} {...props} />
);

export const EditIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.Edit} {...props} />
);

export const ExportIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.Export} {...props} />
);

export const TagIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.Tag} {...props} />
);

export const UserIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.User} {...props} />
);

export const AdminUserIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.Admin} {...props} />
);

export const SearchIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.Search} {...props} />
);

export const MapIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.Map} {...props} />
);

export const FolderOpenIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.FolderOpen} {...props} />
);

export const FolderClosedIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.FolderClosed} {...props} />
);

export const ProjectIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.Project} {...props} />
);

export const WarningIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.Warning} {...props} />
);

export const HistoryIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.History} {...props} />
);

export const ConfigIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.Config} {...props} />
);

export const SpinnerIcon = (props: FixedIconProps) => {
    const { className, ...rest } = props;
    return (
        <Icon
            icon={Icons.Spinner}
            className={classNames('fa-spin', className)}
            {...rest}
        />
    );
};

export const TextFileIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.TextFile} {...props} />
);
export const CsvFileIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.CsvFile} {...props} />
);
export const PdfFileIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.PdfFile} {...props} />
);
export const ZipFileIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.ZipFile} {...props} />
);

export const CodeFileIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.CodeFile} {...props} />
);
export const ImageFileIcon = (props: FixedIconProps) => (
    <Icon icon={Icons.ImageFile} {...props} />
);

export const MosaicPendingIcon = () => (
    <i className="fa-solid fa-circle-pause" title="匿名化待ち" />
);

export const MosaicRunningIcon = () => (
    <i className="fa-solid fa-spinner fa-spin" title="匿名化中" />
);

export const MosaicErrorIcon = () => (
    <i
        className="fa-solid fa-triangle-exclamation"
        title="匿名化に失敗しました"
    />
);

export const MovieSliceIcon = () => (
    <i className="fa-solid fa-film fa-flip icon-animation" />
);
