import React, { useState } from 'react';

import { TenantModel } from '../../../models/TenantModel';
import { getTenantsForSuperAdmin, tenantApi } from '../../../APIs/tenants';
import { TenantCreate } from './TenantCreate';
import { TenantList } from './TenantList';
import { Loading } from '../../atomic/effects';
import { useGet } from '../../../hooks/useApi';

export const SuperAdminTenantIndex = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [tenants, setTenants] = React.useState<Array<TenantModel>>([]);

    const { data: getAdminTenantData, mutate: mutateAdminTenantData } = useGet(
        tenantApi.get.getTenantsForSuperAdmin
    );

    React.useEffect(() => {
        if (getAdminTenantData) {
            setTenants(getAdminTenantData.data);
        }
    }, [getAdminTenantData]);

    const mutate = () => {
        mutateAdminTenantData();
    };

    if (!tenants) {
        return (
            <>
                <Loading />
            </>
        );
    }

    return (
        <div className="app-main admin-tenant">
            <div className="header">
                <h2 className="header-name">テナント一覧</h2>
                <TenantCreate
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    mutate={mutate}
                />
                <TenantList tenants={tenants} mutate={mutate} />
            </div>
        </div>
    );
};
