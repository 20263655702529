import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useProjectDownloader } from '../ProjectDownloader';

import { reformat } from '../../utils/DateUtils';
import {
    Icon,
    ExportIcon,
    Icons,
    ProjectIcon,
    DeleteIcon,
} from './../atomic/icons';
import { Button } from './../atomic/buttons';
import { SlideSwitch } from '../atomic/switches';
import { projectApi } from '../../APIs/projectApi';
import {
    FetchFolderProjectResource,
    FetchFolderResource,
} from '../../APIs/foldersApi';
import { mutateAsync, useGet } from '../../hooks/useApi';
import { TaskState } from '../../models/TaskModel';
import { DialogBox, CancelTaskDialog } from '../atomic/dialog';

import { useTenant } from '../../contexts/TenantContext';
import { UserName } from '../atomic/text';
import { safeUrl } from '../../utils/safeUrl';
import { useMutationDialog } from '../../hooks/useMutationDialog';
import { SimpleDialogProps, useDialog } from '../../hooks/useDialog';
import { useUserConfig } from '../../hooks/useUserConfig';
import { StartFolderAiDialog } from './StartFolderAiDialog';
import { useAuth } from '../../contexts/AuthContext';
import { UpdateProjectRequest } from '../../models/ProjectModel';
import { loadLastFileId } from '../reel/ReelHistory';

interface ProjectRowPros {
    folderId: string;
    project: FetchFolderProjectResource;
    onExport: (opts: { projectId: string }) => void;
    onRunAi: (opts: { project: FetchFolderProjectResource }) => void;
    onRemoveProject: (opts: { projectId: string }) => void;
    onCancelTask: (opts: { projectId: string; taskId: string }) => void;
}

const ProjectRow = (props: ProjectRowPros) => {
    const {
        folderId,
        project,
        onExport,
        onRunAi,
        onRemoveProject,
        onCancelTask,
    } = props;
    const { projectId, name, createdUserId, createdAt } = project;
    const updateProject = {
        projectId: project.projectId,
        name: project.name,
        options: project.options,
    } as UpdateProjectRequest;
    const projectTask = useGet(projectApi.get.projectTask(projectId), {
        refreshInterval: 2000,
    });
    const [projectStatus, projectEditable] = useMemo(() => {
        if (!projectTask.data) {
            return ['読込中', false];
        }
        if (!projectTask.data?.data) {
            return ['未実行', true];
        }
        const { status } = projectTask.data.data;
        if (status == TaskState.success) {
            return ['完了', true];
        }
        if (status == TaskState.error) {
            return ['エラー', true];
        }
        if (status === TaskState.running || status === undefined) {
            return ['実行中', false];
        }
        if (status === TaskState.queueing) {
            return ['実行待ち', false];
        }
        return ['未知のステータス', true];
    }, [projectTask.data]);

    const { getTenantUser } = useTenant();
    const creator = getTenantUser(createdUserId);
    const navigate = useNavigate();
    const [beforeUrl, setBeforeUrl] = useState<string>('');

    const { isExternalUser } = useAuth();

    useEffect(() => {
        const fileId = loadLastFileId(projectId);
        if (fileId) {
            setBeforeUrl(
                safeUrl`/app/f/${folderId}/p/${projectId}/f/${fileId}`
            );
        } else {
            setBeforeUrl('');
        }
    }, [projectId]);

    return (
        <tr className="project">
            <td className="name">
                <Link
                    to={beforeUrl || safeUrl`/app/f/${folderId}/p/${projectId}`}
                >
                    <ProjectIcon />
                    {name || projectId}
                </Link>
            </td>
            <td>{projectStatus}</td>
            <td className="creator">
                <UserName user={creator} />
            </td>
            <td className="createdAt">{reformat(createdAt)}</td>
            <td className="controls">
                <Button
                    onClick={() => onExport({ projectId })}
                    disabled={!projectEditable}
                >
                    <ExportIcon />
                    エクスポート
                </Button>
                <Button
                    onClick={() => navigate(`/app/report/${projectId}`)}
                    disabled={!projectEditable}
                >
                    <Icon icon={Icons.Report} />
                    レポート
                </Button>
                {!isExternalUser() && (
                    <>
                        {project.runnable && (
                            <>
                                {projectEditable ? (
                                    <Button
                                        onClick={() => onRunAi({ project })}
                                    >
                                        <Icon icon={Icons.RunAi} />
                                        AI実行
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() =>
                                            onCancelTask({
                                                projectId,
                                                taskId:
                                                    projectTask.data?.data
                                                        ?.aiTaskId ?? '',
                                            })
                                        }
                                        disabled={
                                            !projectTask.data?.data?.aiTaskId
                                        }
                                    >
                                        <Icon icon={Icons.Cancel} />
                                        AI停止
                                    </Button>
                                )}
                            </>
                        )}

                        <Button
                            onClick={() => onRemoveProject({ projectId })}
                            disabled={!projectEditable}
                        >
                            <DeleteIcon />
                            削除
                        </Button>
                    </>
                )}
            </td>
        </tr>
    );
};

const ProjectTags = (props) => {
    const { projects } = props;
    return (
        <ul className="project-tag-list">
            {projects.map((proj, i) => {
                const { name } = proj;
                return (
                    <li className="project-tag" key={i}>
                        <ProjectIcon />
                        <span className="project-name">{name}</span>
                    </li>
                );
            })}
        </ul>
    );
};

interface FolderProjectsProps {
    folder: FetchFolderResource;
    defaultVisible?: boolean;
    onRemoved: (opts: { projectId: string }) => void;
}

const RemoveProjectDialog = (opts: SimpleDialogProps) => (
    <DialogBox title="確認" {...opts}>
        プロジェクトを削除してもよろしいですか？
    </DialogBox>
);

export const FolderProjects = (props: FolderProjectsProps) => {
    const { folder, defaultVisible = false, onRemoved } = props;
    const [visible, setVisible] = React.useState(defaultVisible);
    const { projects }: FetchFolderResource = folder;
    const exportProject = useProjectDownloader();

    const removeProject = useMutationDialog(
        projectApi.mutate.removeProject,
        RemoveProjectDialog,
        {
            onSuccess: (_res, req) => onRemoved(req),
            onError: 'プロジェクトの削除に失敗しました',
        }
    );
    const config = useUserConfig();
    const startFolderAi = useDialog(
        StartFolderAiDialog,
        ({ project }: { project: FetchFolderProjectResource }) => ({
            options: config.get().video,
            async onCommit(request, opts) {
                await mutateAsync(
                    projectApi.mutate.startFolderAiProcess,
                    {
                        projectId: project.projectId,
                        ...request,
                    },
                    opts
                );
            },
            onError: 'AIの実行に失敗しました',
        }),
        []
    );

    const cancelTask = useMutationDialog(
        projectApi.mutate.cancelProcess,
        CancelTaskDialog,
        { onError: 'AIの停止に失敗しました' }
    );
    if (!projects || projects.length === 0) return null;
    return (
        <>
            <div className="details-outer projects">
                <div className="detail-head">
                    {visible && <div className="dummy-spacer" />}
                    {!visible && <ProjectTags projects={projects} />}
                    <div className="controls">
                        <SlideSwitch
                            name="project-switch"
                            defaultValue={visible}
                            _onChange={setVisible}
                            text="プロジェクト詳細表示"
                            textPosition="left"
                        />
                    </div>
                </div>
                {visible && (
                    <div className="details">
                        <section>
                            <table className="atomic table project-list">
                                <thead>
                                    <tr>
                                        <th>プロジェクト名</th>
                                        <th>ステータス</th>
                                        <th>作成者</th>
                                        <th>作成日</th>
                                        <th>操作</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projects.map((p, i) => (
                                        <ProjectRow
                                            folderId={folder.folderId}
                                            project={p}
                                            key={p.projectId}
                                            onExport={exportProject.show}
                                            onRunAi={startFolderAi.show}
                                            onRemoveProject={removeProject.show}
                                            onCancelTask={cancelTask.show}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </section>
                    </div>
                )}
            </div>
            {exportProject.dialog}
            {startFolderAi.dialog}
            {removeProject.dialog}
            {cancelTask.dialog}
        </>
    );
};
