import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import * as DateUtils from '../../utils/DateUtils';

import {
    FolderIcon,
    MosaicPendingIcon,
    MosaicRunningIcon,
    MosaicErrorIcon,
    MovieSliceIcon,
} from '../atomic/icons';
import { Table, columnDefiner, CheckBox } from '../atomic/table';
import { TagInput, TagList } from '../atomic/tags';
import { UserName } from '../atomic/text';
import { useTenant } from '../../contexts/TenantContext';
import { Pager } from './Pager';
import { MosaicStatus } from '../../models/ItemModel';

import { SortingState, getSortedRowModel } from '@tanstack/react-table';
import { useGet } from '../../hooks/useApi';
import { projectApi } from '../../APIs/projectApi';
import { FileTypeIcon } from '../atomic/FileTypeIcon';
const _getUserName = (d) => d.original.createdUser?.name || '';

type FileData = {
    parentFolderId: string;
    isFile: boolean;
    id: string;
    name: string;
    createdAt: string;
    createdUserId: string;
    createdUser: { name: string };
    status: string;
    mimeType: string;
    tags: [{ name: string }];
    trClass: string;
    url: string;
    mosaic: null | MosaicStatus;
};
const definer = columnDefiner<FileData>();

const SortableDiv = (props) => {
    const { header, children } = props;
    const { column } = header;
    const { id, getToggleSortingHandler, getIsSorted } = column;
    const sorted = getIsSorted();
    // console.log(id, sorted, column);

    const sorter = getToggleSortingHandler();
    return (
        <div
            className={classNames('sortable', { sorted }, sorted)}
            onClick={sorter}
        >
            {children}
        </div>
    );
};

const isSlice = (id, sliceTasks) => {
    if (!sliceTasks.data?.data.length) {
        return false;
    }
    const result = sliceTasks.data?.data.some((data) => {
        return data.request.files.some((file_id) => {
            return file_id === id;
        });
    });
    return result;
};

const LinkIcon = ({ data }) => {
    const { options } = data.table;
    const { id, mosaic, mimeType } = data.row.original;
    return (
        <>
            {mosaic === 'PENDING' ? (
                <MosaicPendingIcon />
            ) : mosaic === 'RUNNING' ? (
                <MosaicRunningIcon />
            ) : mosaic === 'ERROR' ? (
                <MosaicErrorIcon />
            ) : isSlice(id, options.sliceTasks) ? (
                <MovieSliceIcon />
            ) : (
                <FileTypeIcon mimeType={mimeType} />
            )}
        </>
    );
};

const FileLink = ({ data }) => {
    const { url, name, mimeType } = data.row.original;

    const attrDownload =
        mimeType && (mimeType.includes('video') || mimeType.includes('image'))
            ? {}
            : { download: name };

    return (
        <Link to={url} target="_blank" {...attrDownload}>
            <div className="file name link-area">
                <LinkIcon data={data} />
                <span>{data.getValue()}</span>
            </div>
        </Link>
    );
};

const columns = [
    {
        id: 'selector',
        header: ({ table }) => {
            const { options } = table;
            const indeterminate = table.getIsSomeRowsSelected();
            const onChange = table.getToggleAllRowsSelectedHandler();
            return (
                options.enableRowSelection && (
                    <CheckBox
                        className="bulk-toggler"
                        checked={table.getIsAllPageRowsSelected()}
                        indeterminate={indeterminate}
                        onChange={onChange}
                    />
                )
            );
        },
        cell: (d) => {
            const { options } = d.table;
            const { id, name, isFile } = d.row.original;
            const checked = d.row.getIsSelected();
            const onChange = d.row.getToggleSelectedHandler();
            return (
                options.enableRowSelection && (
                    <CheckBox
                        className={classNames('file-select', {
                            file: isFile,
                            folder: !isFile,
                        })}
                        value={id}
                        name={name}
                        checked={checked}
                        onChange={onChange}
                        key={id}
                    />
                )
            );
        },
    },
    definer.accessor('name', {
        header: ({ header }) => {
            return <SortableDiv header={header}>ファイル名</SortableDiv>;
        },
        cell: (d) => {
            const { options } = d.table;
            const {
                id,
                isFile,
                url,
                mosaic,
                name: fileName,
                mimeType,
            } = d.row.original;
            return (
                <>
                    {isFile && <FileLink data={d} />}
                    {!isFile && (
                        <Link to={`/app/folderlist/${id}`}>
                            <div className="folder name link-area">
                                <FolderIcon />
                                <span>{d.getValue()}</span>
                            </div>
                        </Link>
                    )}
                </>
            );
        },
    }),
    definer.accessor('createdAt', {
        header: ({ header }) => {
            return <SortableDiv header={header}>作成日</SortableDiv>;
        },
        cell: (d) => DateUtils.format(d.getValue()),
    }),
    definer.accessor('createdUser', {
        header: ({ header }) => {
            return <SortableDiv header={header}>作成者</SortableDiv>;
        },
        sortingFn: (a, b, id) => {
            // TODO: test with real data
            const _a = _getUserName(a);
            const _b = _getUserName(b);
            const comp = _a.localeCompare(_b);
            // console.log(id, comp, _a, _b);
            return comp;
        },
        cell: (d) => {
            const user = d.getValue();
            return <UserName className="creator" user={user} />;
        },
    }),
    definer.accessor('tags', {
        header: '属性',
        cell: (d) => {
            const { options } = d.table;
            const { highlightTags, onClickTag } = options as unknown as {
                highlightTags: string[];
                onClickTag?: (tag: TagInput) => void;
            }; // TODO:
            const tags = (d.getValue() ?? []) as any[] as TagInput[];
            if (tags.length === 0) return <></>;
            return (
                <TagList
                    tags={tags}
                    highlight={highlightTags}
                    onClick={onClickTag}
                />
            );
        },
    }),
];

export const FolderList = (props) => {
    const {
        currentFolder,
        checkList = [],
        setCheckList,
        showAll = false,
        highlightTags = [],
        pagination,
        setPage,
        setLimit,
    } = props;
    const { folderId, items } = currentFolder;
    const { getTenantUser } = useTenant();

    const [sorting, setSorting] = React.useState<SortingState>([]);

    const folderIdNullable = folderId ? folderId : null; // for rootId==null

    const list = items.map((d) => {
        const isFile = Boolean(d.fileId);
        const createdUser = getTenantUser(d.createdUserId);
        return {
            ...d,
            isFile,
            trClass: isFile ? 'file' : 'folder',
            id: isFile ? d.fileId : d.folderId,
            name: isFile ? d.fileName : d.folderName,
            createdAt: DateUtils.getTime(d.createdAt),
            createdUser,
        };
    });

    const [rowSelection, setRowSelection] = React.useState({});

    const enableRowSelection =
        setCheckList && typeof setCheckList === 'function';

    const navigate = useNavigate();
    const onClickTag = (tag) => {
        navigate(`/app/foldersearch?tag=${tag.tenantTagId}`);
    };

    const sliceTasks = useGet(
        folderId ? projectApi.get.getVideoSliceStatus(folderId) : null
    );

    const options = {
        enableRowSelection,
        enableSorting: true,
        state: {
            rowSelection,
            sorting,
        },
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        highlightTags,
        onClickTag,
        getTenantUser,
        sliceTasks,
    };

    React.useEffect(() => {
        const keys = Object.keys(rowSelection);
        const selection = list
            .map((d, i) => ({ ...d, listViewIndex: i }))
            .filter((d, i) => keys.includes(String(i)));
        enableRowSelection && setCheckList(selection);
    }, [rowSelection]);

    React.useEffect(() => {
        // only on refresh/cleanup
        if (checkList.length === 0) {
            setRowSelection({});
        }
    }, [checkList.length]);

    const pager = React.useMemo(
        () => (
            <Pager
                pagination={pagination}
                setPage={setPage}
                setLimit={setLimit}
            />
        ),
        [pagination]
    );
    return (
        <>
            {pager}
            <Table
                className="folder-table"
                data={list}
                columns={columns}
                options={options}
            />
            {pager}
        </>
    );
};
