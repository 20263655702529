export interface Header<T extends string = string> {
    id: T;
    text: string;
}

export interface CSVModel<T extends string = string> {
    header: Header<T>[];
    list: Record<T, string | number | null>[];
}

export const createCSVBlob = (csv: CSVModel) => {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const csvEscape = (data: string | number | null | undefined) => {
        if (data === null || data === undefined) {
            return '';
        }
        if (typeof data == 'string') {
            return '"' + data.replace(/"/g, '""') + '"';
        }
        return data;
    };
    const h = csv.header.map(({ text }) => csvEscape(text)).join(',');
    const l = csv.list.map((record) => {
        return csv.header.map(({ id }) => csvEscape(record[id])).join(',');
    });
    return new Blob(
        [
            bom,
            [
                csv.header.map(({ text }) => csvEscape(text)).join(','),
                ...csv.list.map((record) => {
                    return csv.header
                        .map(({ id }) => csvEscape(record[id]))
                        .join(',');
                }),
            ].join('\r\n'),
        ],
        { type: 'text/csv' }
    );
};
