import { action, autorun, observable } from 'mobx';
import { FileMetaCompiledContent } from './models/ProjectMetaModel';
import { FileMetaApi } from './models/ProjectHistoryModel';
import { mutateAsync, useGet } from './hooks/useApi';
import { projectApi } from './APIs/projectApi';
import { useMobxValue } from './hooks/useMobxValue';
import stringify from 'json-stringify-deterministic';
import { ulid } from 'ulidx';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useErrors } from './hooks/useErrors';
import { ErrorWithCause } from './utils/ErrorWithCause';
import { fixPHPArrayFilter } from './utils/fixPHPArrayFilter';
import { createTimeDelay } from './utils/timeDelay';

export class SyncProcess {
    pendingProjects = new Map<
        FileMetaApi,
        { projectId: string; fileId: string }
    >();
    syncing = observable.box(false);
    errors = observable.array<Error>([]);

    constructor() {
        this.sync = action(this.sync);
    }

    sync() {
        if (this.syncing.get()) return;
        this.syncing.set(true);
        (async () => {
            while (this.pendingProjects.size > 0) {
                for (const [
                    fileMetaApi,
                    { projectId, fileId },
                ] of this.pendingProjects.entries()) {
                    const isDone = await this.syncProject(
                        projectId,
                        fileId,
                        fileMetaApi
                    );
                    if (isDone) {
                        this.pendingProjects.delete(fileMetaApi);
                    }
                }
            }
        })().finally(
            action(() => {
                this.syncing.set(false);
            })
        );
    }
    onProjectPending(projectId: string, fileId: string, api: FileMetaApi) {
        this.pendingProjects.set(api, { projectId, fileId });
        this.sync();
    }
    async syncProject(
        projectId: string,
        fileId: string,
        fileMetaApi: FileMetaApi
    ) {
        try {
            const delay = createTimeDelay(500);
            while (fileMetaApi.hasPending) {
                await delay();
                const update = { id: ulid(), entries: fileMetaApi.upSync(20) };
                try {
                    const res = await mutateAsync(
                        projectApi.mutate.updateFileMeta(projectId, fileId),
                        {
                            update: stringify(update),
                        }
                    );
                    const { data } = res.data;
                    if (res.status !== 200 || !data) {
                        throw new Error(
                            `Unexpected status ${res.status} and data ${data}`
                        );
                    }
                    if (!data.projectUpdateId) {
                        console.warn('[sync] Empty result after send received');
                        return;
                    }
                    if (
                        fileMetaApi.lastUpdateId &&
                        fileMetaApi.lastUpdateId > data.projectUpdateId
                    ) {
                        return;
                    }
                    const { update: receivedRaw, compiled: compiledRaw } = data;
                    let received: typeof update;
                    let compiled: FileMetaCompiledContent;
                    try {
                        received = JSON.parse(receivedRaw);
                        compiled = JSON.parse(compiledRaw);
                    } catch (err) {
                        return;
                    }
                    if (
                        received.id === update.id &&
                        fileMetaApi.lastUpdateId ===
                            compiled.lastProjectUpdateId
                    ) {
                        console.log('[sync] Simple update. No change.');
                    } else {
                        fileMetaApi.downSync(compiled.fileMeta, false);
                    }
                    fileMetaApi.lastUpdateId = data.projectUpdateId;
                } catch (err) {
                    this.errors.push(
                        new ErrorWithCause(
                            `Error while syncing projectId=${projectId} fileId=${fileId}. Update didn't go through.`,
                            { cause: err }
                        )
                    );
                }
            }
            return true;
        } catch (err) {
            this.errors.push(err);
            return false;
        }
    }
    useFileMetaApi(projectId: string, fileId: string) {
        const [isSyncing] = useMobxValue(this.syncing);
        const fromServer = useGet(
            projectId && fileId
                ? projectApi.get.fileMeta(projectId, fileId)
                : null,
            {
                refreshInterval: 1000,
                isPaused: () => isSyncing,
            }
        );
        const api = useMemo(() => {
            return new FileMetaApi(
                this.onProjectPending.bind(this, projectId, fileId)
            );
        }, [projectId, fileId]);
        useEffect(() => {
            fromServer.mutate();
        }, [api]);
        useEffect(() => {
            if (!fromServer.data?.data) return;
            if (!api) return;
            if (isSyncing) return;
            const { compiled, projectUpdateId, undoAble } =
                fromServer.data.data;
            if (!compiled) return;
            try {
                fromServer.data.data?.projectUpdateId;
                const data = JSON.parse(compiled) as FileMetaCompiledContent;
                data.fileMeta.tags = fixPHPArrayFilter(data.fileMeta.tags);
                if (!api.lastUpdateId || api.lastUpdateId < projectUpdateId) {
                    console.log('[sync] downsyncing new data');
                    api.downSync(data.fileMeta, undoAble);
                    api.lastUpdateId = projectUpdateId;
                } else {
                    console.log('[sync] skipping downsync');
                }
            } catch (err) {
                console.warn('Error while parsing fileMeta update from server');
                console.warn(err);
            }
        }, [fromServer.lastUpdate, api, isSyncing]);
        return api;
    }
}

export const SyncContext = createContext<SyncProcess>(new SyncProcess());

export const useIsSyncing = () => {
    const syncProcess = useContext(SyncContext);
    return useMobxValue(syncProcess.syncing)[0];
};

export const useSyncErrors = () => {
    const syncProcess = useContext(SyncContext);
    const { appendError } = useErrors();
    useEffect(
        autorun(() => {
            while (syncProcess.errors.length > 0) {
                const error = syncProcess.errors.shift()!;
                console.log(error);
                appendError(error.toString());
            }
        })
    );
};
