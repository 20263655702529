import React, { DependencyList } from 'react';
import { DialogBox } from '../atomic/dialog';
import { DialogProps, useSimpleDialog } from '../../hooks/useDialog';
import { FileIcon, FolderIcon } from '../atomic/icons';
import { FilesAPI } from '../../APIs/filesApi';

export type DeleteFilesFileInfo = { id: string; isFile: boolean; name: string };
export type DeleteFilesDialogProps = DialogProps<
    { files: DeleteFilesFileInfo[]; parentId: string },
    void
>;
export const useFileDeleteDialog = (
    {
        onDelete,
        onPreDelete,
    }: { onDelete?: () => void; onPreDelete?: () => void },
    deps: DependencyList
) =>
    useSimpleDialog(
        DeleteFilesDialog,
        (options) => ({
            options,
            async onCommit() {
                const ids = options.files.map((d) => d.id);
                onPreDelete?.();
                await FilesAPI.delete(options.parentId, ids);
                onDelete?.();
            },
            onError: (error) => error.message || '削除に失敗しました',
        }),
        deps
    );

export const DeleteFilesDialog = (props: DeleteFilesDialogProps) => {
    const {
        options: { files },
        ...rest
    } = props;
    return (
        <DialogBox className="delete-files" okText="削除" {...rest}>
            <div className="inner">
                <h3 className="msg">
                    <i className="fa-solid fa-triangle-exclamation" />
                    以下のファイルを削除します
                </h3>
                <ul className="delete-list">
                    {files.map((f) => {
                        return (
                            <li className="delete-item" key={f.id}>
                                {f.isFile ? <FileIcon /> : <FolderIcon />}
                                {f.name}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </DialogBox>
    );
};
