import React, { FC } from 'react';
import { TaskLogModel, TaskSummaryModel } from '../../models/TaskModel';
import { difMinutes, reformat } from '../../utils/DateUtils';
import { Link } from 'react-router-dom';
import { safeUrl } from '../../utils/safeUrl';
import { useTenant } from '../../contexts/TenantContext';
import { UserMail, UserName, UserTenantName } from '../atomic/text';
import { useAuth } from '../../contexts/AuthContext';
import { TenantModel } from '../../models/TenantModel';
import { SpinnerIcon } from '../atomic/icons';

export type TaskLogProp = {
    tasks: TaskSummaryModel[] | null;
    tenants;
    loading: boolean;
};

export const TaskLogs: FC<TaskLogProp> = ({ tasks, tenants, loading }) => {
    const { isSuperAdmin } = useAuth();

    return (
        <div className="log-list">
            <table className="atomic table">
                <thead>
                    <tr>
                        {isSuperAdmin() ? <th>テナント名</th> : ''}
                        <th>プロジェクト名</th>
                        <th>AI名</th>
                        <th>フォルダ名</th>
                        <th>ステータス</th>
                        <th>作成日時</th>
                        <th>更新日時</th>
                        <th>実行時間(分)</th>
                        <th>推論時間(分)</th>
                        <th>実行ユーザー</th>
                        <th>メールアドレス</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks?.map((task) => {
                        return (
                            <Row
                                key={task.aiTaskId}
                                task={task}
                                tenants={tenants}
                            />
                        );
                    })}
                    {loading && (
                        <tr>
                            <td colSpan={isSuperAdmin() ? 11 : 10}>
                                <SpinnerIcon /> Now Loading...
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

type RowLinkProp = {
    to: string;
    name: string;
};

const RowLink: FC<RowLinkProp> = ({ to, name }) => {
    const { isSuperAdmin } = useAuth();
    if (isSuperAdmin()) {
        return <>{name}</>;
    }
    return <Link to={to}>{name}</Link>;
};

type RowProp = {
    task: TaskSummaryModel;
    tenants?: Array<TenantModel>;
};

const Row: FC<RowProp> = (props) => {
    const { task, tenants } = props;

    const { getTenantUser } = useTenant();
    const { isSuperAdmin } = useAuth();

    const user = getTenantUser(task.createdUserId);

    return (
        <tr>
            {isSuperAdmin() ? (
                <td>
                    {tenants ? (
                        <UserTenantName user={user} tenants={tenants} />
                    ) : (
                        <UserTenantName user={user} />
                    )}
                </td>
            ) : (
                ''
            )}
            <td>
                <RowLink
                    to={safeUrl`/app/f/${task.folderId}/p/${task.projectId!}`}
                    name={task.projectName}
                />
            </td>
            <td>{task.aiAnalysisTypeNameJa}</td>
            <td>
                <RowLink
                    to={safeUrl`/app/folderlist/${task.folderId}`}
                    name={task.folderName}
                />
            </td>
            <td className="app-status">
                <p className={task.status.toLowerCase()}>{task.status}</p>
            </td>
            <td>{reformat(task.createdAt)}</td>
            <td>{reformat(task.updatedAt)}</td>
            <td>
                {task.status !== 'QUEUEING'
                    ? difMinutes(task.createdAt, task.updatedAt)
                    : '-'}
            </td>
            <td>
                {task.aiRunTime ? Math.ceil(task.aiRunTime / 1000 / 60) : '-'}
            </td>
            <td>
                <UserName user={user} />
            </td>
            <td>
                <UserMail user={user} />
            </td>
        </tr>
    );
};
