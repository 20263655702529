import React, { FC, ReactNode } from 'react';

import { useAuth } from './AuthContext';
import { getUsers } from '../APIs/userApi';
import throttle from 'lodash/throttle';
import { TenantModel, TenantOptionsModel } from '../models/TenantModel';
import { getTenantOptions, getIsExternalApi, tenantApi } from '../APIs/tenants';
import { useGet } from '../hooks/useApi';

export interface User {
    tenantId: string;
    userId: string;
    familyName: string;
    givenName: string;
    role: string;
    email: string;
}

export interface UserIdMap {
    [userId: string]: User;
}

type TenantContextType = {
    tenantUsers: User[];
    getTenantUser: (id: string) => User | undefined;
    tenantOptions: TenantOptionsModel | null;
    tenantId: string;
    isExternalApi: boolean;
};

const TenantContext = React.createContext<TenantContextType>({
    tenantUsers: [],
    getTenantUser: () => undefined,
    tenantOptions: null,
    tenantId: '',
    isExternalApi: false,
});

const reduceUsersToIdMap = (users) =>
    users.reduce((map, user) => {
        map[user.userId] = user;
        return map;
    }, {});

const MIN_UPDATE_INTERVAL = 1000 * 60;
const FETCH_THROTTLE = 1000;
let _lastUpdate = 0;
let _lastHash = '';

export const TenantProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { isAdmin, isSuperAdmin, fetchUser } = useAuth();
    const user = fetchUser();
    const tenantId = user?.attributes && user.attributes['custom:tenant_id'];
    //console.log({ tenantId, user });

    const [tenantUsers, setTenantUsers] = React.useState([]);
    const [tenantUserMap, setTenantUserMap] = React.useState({});
    const [tenantOptions, setTenantOptions] =
        React.useState<TenantOptionsModel | null>(null);
    const [isExternalApi, setIsExternalApi] = React.useState(false);
    const refresh = throttle(() => {
        getUsers()
            .then(async (resp) => {
                const users = resp.data;
                const hash = await sha256(JSON.stringify(users));
                return { users, hash };
            })
            .then(({ users, hash }) => {
                const diffFound = _lastHash !== hash;
                // console.log({ diffFound, hash, _lastHash });
                if (diffFound) {
                    setTenantUsers(users);
                    setTenantUserMap(reduceUsersToIdMap(users));
                    console.log('TenantProvider.refresh', {
                        users,
                        tenantUserMap,
                    });
                    _lastHash = hash;
                }
            });
        if (tenantId && (isAdmin() || isSuperAdmin())) {
            getTenantOptions({
                tenantId: tenantId,
                category: 'kintone',
                keys: ['id', 'pass', 'endpoint'],
            })
                .then((resp) => {
                    if (resp.success) {
                        setTenantOptions(resp.data);
                    } else {
                        console.warn(resp);
                        setTenantOptions(null);
                    }
                })
                .catch((err) => {
                    console.warn(err);
                });
        } else {
            setTenantOptions(null);
        }

        _lastUpdate = Date.now();
    }, FETCH_THROTTLE);

    React.useEffect(() => {
        refresh();
        if (tenantId) {
            getIsExternalApi()
                .then((resp) => {
                    if (resp.data) {
                        setIsExternalApi(resp.data);
                    }
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }, [tenantId]);

    const getTenantUser = (id) => {
        const now = Date.now();
        const delta = now - _lastUpdate;
        if (delta > MIN_UPDATE_INTERVAL) {
            refresh();
        }
        return tenantUserMap[id];
    };

    return (
        <TenantContext.Provider
            value={{
                tenantUsers,
                getTenantUser,
                tenantOptions,
                tenantId: tenantId ?? '',
                isExternalApi,
            }}
        >
            {children}
        </TenantContext.Provider>
    );
};

export const useTenant = () => React.useContext(TenantContext);

async function sha256(text) {
    const uint8 = new TextEncoder().encode(text);
    const digest = await crypto.subtle.digest('SHA-256', uint8);
    return Array.from(new Uint8Array(digest))
        .map((v) => v.toString(16).padStart(2, '0'))
        .join('');
}
