// 書式設定
export const comma = (num: number) => {
    if (!num) {
        // null や undefined は 0 にしておく
        num = 0;
    }
    const s = String(num).split('.');
    let ret = String(s[0]).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    if (s.length > 1) {
        ret += '.' + s[1];
    }
    return ret;
};
