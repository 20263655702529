import React, { FC } from 'react';

import { useAuth } from '../../../contexts/AuthContext';
import { DialogBox } from '../../atomic/dialog';
import { Button } from '../../atomic/buttons';
import { TenantFormDialog } from './TenantFormDialog';

type Prop = {
    isOpen: boolean;
    setIsOpen: any;
    mutate: any;
};

export const TenantCreate: FC<Prop> = ({ isOpen, setIsOpen, mutate }) => {
    const ref = React.useRef<DialogBox>();
    const { fetchUser } = useAuth();

    const openModal = () => {
        setIsOpen(true);
    };

    React.useEffect(() => {
        if (!isOpen) mutate();
    }, [isOpen]);
    /*
    const onCancel = React.useCallback(() => {
        setIsOpen(false);
    }, []);
*/
    return (
        <>
            <div className="create_tenant_button">
                <Button className="primary" onClick={openModal}>
                    テナント作成
                </Button>
                {isOpen && (
                    <TenantFormDialog isOpen={isOpen} setIsOpen={setIsOpen} />
                )}
            </div>
        </>
    );
};
