import React from 'react';
import { useGet } from '../../hooks/useApi';
import { useTenant } from '../../contexts/TenantContext';
import { GroupModel, GroupUserModel, userApi } from '../../APIs/userApi';
import { columnDefiner, Table } from '../atomic/table';
import { Button, IconButton } from '../atomic/buttons';
import { Icons } from '../atomic/icons';
import { UserMail, UserName } from '../atomic/text';
import { GroupEditModal } from './GroupEditModal';
import { Link } from 'react-router-dom';
import { GroupUserEditModal } from './GroupUserEditModal';
import { GroupFolderEditModal } from './GroupFolderEditModal';
import { DialogBox } from '../atomic/dialog';

interface GroupsData extends GroupModel {
    trClass: string;
}
const definer = columnDefiner<GroupsData>();

const columns = [
    definer.accessor('groupId', {
        header: 'グループ名',
        cell: (d) => {
            const { name, groupId } = d.row.original;
            const { setIsOpen, setGroupId } = d.table.options;

            const onClick = () => {
                setGroupId(groupId);
                setIsOpen(true);
            };

            return (
                <>
                    <Link onClick={onClick} to={''}>
                        {name}
                    </Link>
                </>
            );
        },
    }),
    {
        header: 'ユーザー',
        cell: (d) => {
            const { groupUsers } = d.row.original;

            return <UsersView groupUsers={groupUsers} />;
        },
    },

    {
        header: 'フォルダ',
        cell: (d) => {
            const { groupFolders } = d.row.original;

            return (
                <ul className="alignedStyle">
                    {groupFolders.map((groupFolder) => {
                        return (
                            <li key={groupFolder.groupFolderId}>
                                <Link
                                    to={`/app/folderlist/${groupFolder.folderId}`}
                                >
                                    <div className="folder name link-area">
                                        <span>
                                            {groupFolder.folder.folderName}
                                        </span>
                                    </div>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            );
        },
    },

    {
        header: '操作',
        cell: (d) => {
            const group = d.row.original;
            const { setIsOpenUserModal, setIsOpenFolderModal, setGroupId } =
                d.table.options;
            return (
                <Actions
                    key={'actions' + group.id}
                    group={group}
                    setIsOpenUserModal={setIsOpenUserModal}
                    setIsOpenFolderModal={setIsOpenFolderModal}
                    setGroupId={setGroupId}
                />
            );
        },
    },
    {
        header: '削除',
        cell: (d) => {
            const group = d.row.original;
            const { setShowConfirm, setDeleteGroupId, setConfirmMessage } =
                d.table.options;
            return (
                <DeleteActions
                    key={'delete_actions' + group.id}
                    group={group}
                    setShowConfirm={setShowConfirm}
                    setDeleteGroupId={setDeleteGroupId}
                    setConfirmMessage={setConfirmMessage}
                />
            );
        },
    },
];
type UsersViewProps = {
    groupUsers: Array<GroupUserModel>;
};
const UsersView: React.FC<UsersViewProps> = ({ groupUsers }) => {
    const { tenantUsers } = useTenant();

    return (
        <ul className="alignedStyle">
            {groupUsers.map((groupUser) => {
                const user = tenantUsers.find(
                    (user) => user.userId === groupUser.userId
                );
                if (!user?.familyName || !user?.givenName)
                    return (
                        <li key={groupUser.groupUserId}>
                            <UserMail user={user} />
                        </li>
                    );
                return (
                    <li key={groupUser.groupUserId}>
                        <UserName user={user} />
                    </li>
                );
            })}
        </ul>
    );
};

type ActionsProps = {
    group: GroupModel;
    setGroupId: Function;
    setIsOpenUserModal;
    setIsOpenFolderModal;
};
const Actions: React.FC<ActionsProps> = ({
    group,
    setGroupId,
    setIsOpenUserModal,
    setIsOpenFolderModal,
}) => {
    const onClickOpenUserModal = () => {
        setGroupId(group.groupId);
        setIsOpenUserModal(true);
    };
    const onClickFolderOpenModal = () => {
        setGroupId(group.groupId);
        setIsOpenFolderModal(true);
    };

    return (
        <>
            <div>
                <IconButton
                    icon="fa-solid fa-user-plus"
                    onClick={onClickOpenUserModal}
                />
            </div>
            <div>
                <IconButton
                    icon="fa-solid fa-folder-plus"
                    onClick={onClickFolderOpenModal}
                />
            </div>
        </>
    );
};

type DeleteActionsProps = {
    group: GroupModel;
    setDeleteGroupId;
    setShowConfirm;
    setConfirmMessage;
};
const DeleteActions: React.FC<DeleteActionsProps> = ({
    group,
    setDeleteGroupId,
    setShowConfirm,
    setConfirmMessage,
}) => {
    const onClickDelete = () => {
        setConfirmMessage(`${group.name}を削除してよろしいですか？`);
        setDeleteGroupId(group.groupId);
        setShowConfirm(true);
    };

    return (
        <>
            <IconButton icon="fa-solid fa-trash" onClick={onClickDelete} />
        </>
    );
};

type Prop = {};
export const AdminUserGroupList: React.FC<Prop> = () => {
    const userApiGroups = useGet(userApi.get.groups());
    const [groups, setGroups] = React.useState<Array<GroupModel>>([]);

    const [isOpenUserModal, setIsOpenUserModal] = React.useState(false);
    const [isOpenFolderModal, setIsOpenFolderModal] = React.useState(false);

    const [showConfirm, setShowConfirm] = React.useState(false);
    const [deleteGroupId, setDeleteGroupId] = React.useState<string | null>(
        null
    );
    const [confirmMessage, setConfirmMessage] = React.useState('');

    const { data: groupsResponseData } = userApiGroups;

    const [isOpen, setIsOpen] = React.useState(false);
    const [groupId, setGroupId] = React.useState(undefined);

    React.useEffect(() => {
        if (groupsResponseData) setGroups(groupsResponseData.data);
    }, [groupsResponseData]);

    const options = {
        groups: Array<GroupModel>,
        isOpen,
        setIsOpen,
        setGroupId,
        isOpenUserModal,
        setIsOpenUserModal,
        isOpenFolderModal,
        setIsOpenFolderModal,
        setShowConfirm,
        setDeleteGroupId,
        setConfirmMessage,
    };

    React.useEffect(() => {
        if (!isOpenUserModal || !isOpenFolderModal) {
            userApiGroups.mutate();
        }
    }, [isOpenUserModal, isOpenFolderModal]);

    const openModal = () => {
        setGroupId(undefined);
        setIsOpen(true);
    };
    const onConfirmOK = React.useCallback(() => {
        if (deleteGroupId) {
            userApi.delete.group(deleteGroupId);
        }
        userApiGroups.mutate();
        resetConfirm();
    }, [deleteGroupId]);
    const onConfirmCancel = React.useCallback(() => {
        resetConfirm();
    }, []);

    const resetConfirm = () => {
        setConfirmMessage('');
        setDeleteGroupId(null);
        setShowConfirm(false);
    };

    return (
        <div className="app-main group">
            <h2>ユーザグループの編集</h2>
            <Button className="primary" onClick={openModal}>
                グループ追加
            </Button>
            <GroupEditModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                groupId={groupId}
                mutate={userApiGroups.mutate}
            />
            {!!groupId && (
                <>
                    <GroupUserEditModal
                        groupId={groupId}
                        isOpen={isOpenUserModal}
                        setIsOpen={setIsOpenUserModal}
                    />
                    <GroupFolderEditModal
                        groupId={groupId}
                        isOpen={isOpenFolderModal}
                        setIsOpen={setIsOpenFolderModal}
                    />
                </>
            )}

            <div className="groups-list">
                <Table
                    className="group-table"
                    data={groups}
                    columns={columns}
                    options={options}
                />
            </div>
            {showConfirm && (
                <DialogBox
                    title="グループ削除"
                    onOK={onConfirmOK}
                    onCancel={onConfirmCancel}
                >
                    {confirmMessage}
                </DialogBox>
            )}
        </div>
    );
};
