import React from 'react';
import { FC } from 'react';
import { useGet } from '../../hooks/useApi';
import { tenantApi } from '../../APIs/tenants';

type Prop = {};
export const TenantUserStatus: FC<Prop> = () => {
    const { data: status } = useGet(tenantApi.get.getTenantStatus);
    return (
        <>
            <ul className="tenant-status-list">
                <li>
                    <b>現在ユーザー数:</b> {status?.data.numberOfUsers}
                </li>
                <li>
                    <b>最大ユーザー数:</b> {status?.data.maxUserLicenses}
                </li>
            </ul>
        </>
    );
};
