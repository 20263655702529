import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams, useSearchParams } from 'react-router-dom';
import { Reel } from './reel/Reel';
import { ErrorPanelEx } from './Errors';
import { projectApi } from '../APIs/projectApi';
import { useGet } from '../hooks/useApi';
import { Loading } from './atomic/effects';
import { safeUrl } from '../utils/safeUrl';
import { useErrors } from '../hooks/useErrors';
import { useAuth } from '../contexts/AuthContext';
import { NavigateToItemContext, NavigateToItemHandler } from './atomic/links';
import { FilesAPI } from '../APIs/filesApi';

export const RedirectToProject = () => {
    const { projectId } = useParams() as { projectId: string };
    const navigate = useNavigate();
    const project = useGet(projectApi.get.project(projectId), REFRESH_EVERY_5S);
    useEffect(() => {
        if (project.error) return;
        const folderId = project.data?.data?.folderId;
        if (!folderId) return;
        navigate(safeUrl`/app/f/${folderId}/p/${projectId!}`, {
            replace: true,
        });
    }, [project.data]);
    if (project.error) {
        return (
            <ErrorPanelEx to="/app/folderlist/" linkText="トップへ戻る">
                プロジェクトは見つかりません
            </ErrorPanelEx>
        );
    }
    return <Loading />;
};

export const RedirectToProjectOfFolder = () => {
    const { projectId, folderId } = useParams() as {
        projectId: string;
        folderId: string;
    };
    const navigate = useNavigate();
    const ids = useGet(FilesAPI.nonFrameIds({ folderId }));
    const fileId = useMemo(() => ids.data?.data[0], [ids.data]);
    useEffect(() => {
        if (ids.error) return;
        if (!fileId) return;
        navigate(getProjectItemLink({ fileId, projectId, folderId }), {
            replace: true,
        });
    }, [fileId, ids.error]);
    if (ids.error) {
        return (
            <ErrorPanelEx
                to={safeUrl`/app/folderlist/${folderId}`}
                linkText="トップへ戻る"
            >
                プロジェクトは見つかりません
            </ErrorPanelEx>
        );
    }
    if (ids.data && !fileId) {
        return (
            <ErrorPanelEx
                to={safeUrl`/app/folderlist/${folderId!}`}
                linkText="フォルダへ戻る"
            >
                フォルダ内に画像ファイル・動画ファイルが存在しません
            </ErrorPanelEx>
        );
    }
    return <Loading />;
};

const REFRESH_EVERY_5S = {
    keepPreviousData: true,
    refreshInterval: 5000,
} as const;

export type GetProjectLinkProps = {
    fileId: string;
    projectId: string;
    folderId: string;
    videoTimeSeconds?: number;
    sourceId?: string;
};
export function getProjectItemLink({
    fileId,
    projectId,
    folderId,
    videoTimeSeconds,
    sourceId,
}: GetProjectLinkProps) {
    if (!fileId) {
        debugger;
    }
    let url = safeUrl`/app/f/${folderId}/p/${projectId}/f/${fileId}`;
    if (sourceId) {
        url += safeUrl`/${sourceId}`;
    }
    if (videoTimeSeconds) {
        url += `?t=${videoTimeSeconds}ms`;
    }
    return url;
}

function nearestEntry<T>(items: T[], index: number) {
    if (index > 1) {
        index -= 1;
    }
    if (index < 0) {
        index = 0;
    } else if (index >= items.length) {
        index = items.length - 1;
    }
    return items[index];
}

export const Project = () => {
    const {
        projectId,
        folderId,
        fileId,
        sourceFileId: sourceId,
    } = useParams() as {
        projectId: string;
        folderId: string;
        fileId?: string;
        sourceFileId?: string;
    };
    const folder = useGet(FilesAPI.item(folderId), REFRESH_EVERY_5S);
    const fileIds = useGet(FilesAPI.nonFrameIds({ folderId }));
    const sourceFileIds = useGet(
        sourceId ? FilesAPI.frameIds({ folderId, sourceId }) : null
    );
    const [prevFileIndex, setPrevFileIndex] = useState(0);
    const [prevChildFileIndex, setPrevChildFileIndex] = useState(0);
    const [search] = useSearchParams();
    const { appendError } = useErrors();
    const videoTimeSeconds: number = useMemo(() => {
        if (!search) return 0;
        const t = search.get('t');
        if (!t) return 0;
        const parts = /^(\d+)ms$/.exec(t);
        if (!parts) return 0;
        return parseInt(parts[1], 10);
    }, [search]);
    const project = useGet(projectApi.get.project(projectId), REFRESH_EVERY_5S);
    const file = useGet(fileId ? FilesAPI.item(fileId) : null);

    const navigate = useNavigate();
    const navigateToFile = useCallback<NavigateToItemHandler>(
        ({ fileId, videoTimeSeconds, replace, sourceId }) => {
            if (!fileId) {
                navigate(safeUrl`/app/f/${folderId}/p/${projectId!}`);
                return;
            }
            navigate(
                getProjectItemLink({
                    fileId,
                    folderId,
                    projectId,
                    videoTimeSeconds,
                    sourceId,
                }),
                { replace: !!replace, preventScrollReset: true }
            );
        },
        [navigate, folderId, projectId]
    );
    const index = useMemo(() => {
        if (fileIds.isLoading) return -3;
        const ids = fileIds.data?.data;
        if (!ids) return -2;
        if (!fileId) return 0;
        const effectiveId = sourceId ? sourceId : fileId;
        return ids.indexOf(effectiveId);
    }, [file.data, fileIds.data, fileIds.isLoading]);
    const subIndex = useMemo(() => {
        if (!sourceId) return -4;
        if (sourceFileIds.isLoading) return -3;
        const ids = sourceFileIds.data?.data;
        if (!ids) return -2;
        if (!fileId) return -1;
        return ids.indexOf(fileId);
    }, [sourceId, fileId, sourceFileIds.isLoading, sourceFileIds.data]);
    useEffect(() => {
        if (folder.isLoading) return;
        if (file.isLoading) return;
        if (fileIds.isLoading) return;
        if (sourceFileIds.isLoading) return;
        if (!fileIds.data) return;
        if (file.error || file.data?.data.parentFolderId !== folderId) {
            appendError(
                `画像ファイルのロードに失敗しました。他のユーザによってファイルが削除された可能性があります`
            );
            folder.mutate();
            const newFileId =
                index > -1 && sourceFileIds.data?.data
                    ? nearestEntry(sourceFileIds.data.data, prevChildFileIndex)
                    : nearestEntry(fileIds.data.data, prevFileIndex);
            navigate(
                newFileId
                    ? getProjectItemLink({
                          projectId,
                          folderId,
                          fileId: newFileId,
                          sourceId: subIndex > -1 ? sourceId : undefined,
                      })
                    : safeUrl`/app/f/${folderId}/p/${projectId!}`,
                {
                    replace: true,
                }
            );
        } else if (!file.isLoading) {
            if (index > -1 && prevFileIndex !== index) {
                setPrevFileIndex(index);
            }
            if (subIndex >= -1 && prevChildFileIndex !== index) {
                setPrevChildFileIndex(index);
            }
        }
    }, [
        folderId,
        file.error,
        file.isLoading,
        index,
        subIndex,
        project.data?.data,
        !fileIds.isLoading,
        !sourceFileIds.isLoading,
    ]);
    useEffect(() => {
        if (!file.data) return;
        if (!fileId) return;
        if (!project.data?.data) return;
        const projectFolderId = project.data.data.folderId;
        if (
            projectFolderId === folderId &&
            file.data.data.parentFileId === sourceId
        )
            return;

        navigate(
            getProjectItemLink({
                projectId,
                folderId: projectFolderId,
                fileId,
                sourceId: file.data.data.parentFileId ?? undefined,
                videoTimeSeconds,
            }),
            {
                replace: true,
            }
        );
    }, [project.data, folderId, sourceId, file.data, videoTimeSeconds]);
    useEffect(() => {
        if (!project.error) return;
        navigate(safeUrl`/app/project/${projectId}`);
    }, [project.error]);
    const { isExternalUser } = useAuth();
    if (!fileId) {
        return <Loading />;
    }
    if (!projectId || (!project.isLoading && !project.data?.data?.projectId)) {
        return (
            <ErrorPanelEx
                to={safeUrl`/app/folderlist/${folderId}`}
                linkText="トップへ戻る"
            >
                プロジェクトは見つかりません
            </ErrorPanelEx>
        );
    }
    return (
        <NavigateToItemContext value={navigateToFile}>
            <Reel
                projectId={projectId}
                folderId={folderId}
                fileId={fileId}
                sourceFileId={sourceId}
                videoTimeSeconds={videoTimeSeconds}
                readonly={isExternalUser()}
            />
        </NavigateToItemContext>
    );
};
