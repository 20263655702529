import React from 'react';
import {
    CodeFileIcon,
    CsvFileIcon,
    FileIcon,
    ImageFileIcon,
    PdfFileIcon,
    TextFileIcon,
    VideoFileIcon,
    ZipFileIcon,
} from './icons';

export const FileTypeIcon = ({ mimeType }) => {
    if (mimeType.includes('image')) {
        return <ImageFileIcon />;
    }
    if (mimeType.includes('video')) {
        return <VideoFileIcon />;
    }
    if (mimeType.includes('text/csv')) {
        return <CsvFileIcon />;
    }
    if (mimeType.includes('text')) {
        return <TextFileIcon />;
    }
    if (mimeType.includes('json')) {
        return <CodeFileIcon />;
    }

    if (mimeType.includes('pdf')) {
        return <PdfFileIcon />;
    }

    if (mimeType.includes('zip') || mimeType.includes('gzip')) {
        return <ZipFileIcon />;
    }

    return <FileIcon />;
};
