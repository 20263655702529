import React, { RefObject, memo, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDropzone, FileWithPath } from 'react-dropzone';

import { FileIcon } from './icons';
import { Button } from './buttons';
import { InputField, FormField, InputFieldProps } from './forms';

import { UPLOADABLE_CONTENT_TYPES, SHOW_UPLOAD_ALERT_SIZE } from '../../consts';

import { FilesAPI } from '../../APIs/filesApi';
import { isConcreteElementType } from '../../utils/isElementType';
import { Loading } from './effects';
import { ProgressBar } from './progress';
import { getObjectId } from '../../utils/objectId';
import { FoldersAPI } from '../../APIs/foldersApi';
import { checkAbort, isAbortError } from '../../utils/abort';
import { Queue } from '../../utils/queue';
import { mutateAsync, useGet } from '../../hooks/useApi';
import { useUserConfig } from '../../hooks/useUserConfig';
import { useLocalConfigVideoFields } from '../config/useLocalConfigVideoFields';
import { SlideSwitch } from './switches';
import { bgAsync } from '../../utils/bgAsync';
import { MoveSliceOptions } from '../../models/FileModel';
import { DialogProps } from '../../hooks/useDialog';
import { projectApi } from '../../APIs/projectApi';
import { TaskState } from '../../models/TaskModel';

export type DialogOKHandler = (dialogBox: DialogBox) => void;
export type DialogCancelHandler = () => void;

export interface DialogBoxProps {
    className?: string;
    title?: string;
    okText?: string;
    okBtnClass?: string;
    cancelText?: string;
    onOK: DialogOKHandler;
    onCancel: DialogCancelHandler;
    children: React.ReactNode;
    disabled?: boolean;
    disabledCancel?: boolean;
    loading?: boolean;
    errors?: DialogErrorObject[];
}
export interface DialogBoxState {
    errors: DialogErrorObject[];
}
export type DialogErrorObject = {
    response?: { data?: { message?: string } };
    message?: string;
    name?: string;
};
export type DialogError = string | DialogErrorObject;

export const ABORT_ERROR_MESSAGE = 'ユーザによって処理が中断されました';

export function getErrorMessage(
    error: DialogError,
    defaultMessage?: string
): string {
    if (typeof error === 'string') {
        return error;
    }
    if (isAbortError(error)) {
        return ABORT_ERROR_MESSAGE;
    }
    if (error?.name == 'AbortError') {
        return ABORT_ERROR_MESSAGE;
    }
    return (
        error.response?.data?.message ||
        error.message ||
        defaultMessage ||
        '未知のエラーが発生しました'
    );
}

export interface DialogBoxFooterProps {
    children: React.ReactNode | React.ReactNode[];
}
export const DialogBoxFooter = ({ children }: DialogBoxFooterProps) => (
    <>{children}</>
);

function registerDialogNodes(
    children: React.ReactNode | React.ReactNode[],
    refs: Array<React.RefObject<InputField>> = []
): [
    refs: RefObject<InputField<InputFieldProps>>[],
    children: React.ReactNode | React.ReactNode[],
    footer: React.ReactNode | React.ReactNode[],
] {
    const footer: React.ReactNode[] = [];
    return [
        refs,
        React.Children.map(children, (child: React.ReactNode) => {
            if (React.isValidElement(child)) {
                if (isConcreteElementType(child, FormField)) {
                    const ref = React.createRef<InputField>();
                    refs.push(ref);
                    // @ts-ignore
                    return React.cloneElement(child, { ref });
                }
                if (child.type === DialogBoxFooter) {
                    footer.push(child);
                    return;
                }
                if (child.props.children) {
                    const [, childNodes] = registerDialogNodes(
                        child.props.children,
                        refs
                    );
                    return React.cloneElement(child, {}, childNodes);
                }
            }
            return child;
        })?.filter(Boolean),
        footer,
    ];
}

const ErrorLine = memo(({ err }: { err: DialogErrorObject }) => {
    console.log(err);
    return <li className="error">{getErrorMessage(err)}</li>;
});

export class DialogBox extends React.Component<DialogBoxProps, DialogBoxState> {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
        };
    }

    addError(error: DialogError, defaultMessage: string = '') {
        let errObj = typeof error === 'string' ? { message: error } : error;
        let message = errObj.response?.data?.message || errObj.message;
        if (!message) {
            errObj = { message: defaultMessage };
        }
        this.setState({
            errors: [...this.state.errors, errObj],
        });
    }

    clearError() {
        this.setState({ errors: [] });
    }

    render() {
        const {
            className,
            title = undefined,
            okText = 'OK',
            okBtnClass,
            cancelText = 'キャンセル',
            children,
            onOK,
            onCancel,
            disabled,
            loading,
            errors: externalErrors,
            disabledCancel,
        } = this.props;

        const [refs, contentChildren, footerChildren] =
            registerDialogNodes(children);

        const errors = externalErrors
            ? externalErrors.concat(this.state.errors)
            : this.state.errors;

        return (
            <div className="atomic dialog-backdrop">
                <div
                    className={classNames('atomic dialog-box', className)}
                    role="dialog"
                    aria-modal="true"
                >
                    {title && <div className="header">{title}</div>}
                    {errors.length > 0 && (
                        <ul className="errors">
                            {errors.map((err) => (
                                <ErrorLine err={err} key={getObjectId(err)} />
                            ))}
                        </ul>
                    )}
                    <div className="content">{contentChildren}</div>
                    <div className="controls">
                        {!disabledCancel && (
                            <Button className="btn cancel" onClick={onCancel}>
                                {cancelText}
                            </Button>
                        )}
                        <Button
                            className={classNames('btn ok', okBtnClass)}
                            disabled={disabled}
                            onClick={() => {
                                let retVal = true;
                                this.setState({ errors: [] });
                                if (refs.length) {
                                    refs.forEach(
                                        (ref: React.RefObject<InputField>) => {
                                            if (
                                                ref &&
                                                ref.current &&
                                                !ref.current.checkValue()
                                            ) {
                                                retVal = false;
                                            }
                                        }
                                    );
                                }
                                if (!retVal) {
                                    return false;
                                }
                                onOK(this);
                            }}
                        >
                            {okText}
                        </Button>
                    </div>
                    {footerChildren}
                </div>
                {loading && <Loading />}
            </div>
        );
    }
}

type FileUploadBoxProp = {
    className?: string;
    okText?: string;
    cancelText?: string;
    folderId: string;
    isSwhoAlert: boolean;
    uploadFolder?: boolean;
    onDone: () => void;
    onCancel: () => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const getTotalUploadFileSize = (uploadFiles: File[]) =>
    uploadFiles.reduce((sum, file) => sum + file.size, 0);

// TODO: async の直列化と FileAPIやHttpUtilsへの機能移管 issue #157
const uploadFileController = (() => {
    let controller: AbortController | undefined;
    return {
        cancel: (setDisableOptionFields) => {
            if (controller) {
                controller.abort();
                controller = undefined;
                setDisableOptionFields(false);
            }
        },
        start: async (
            rootFolderId: string,
            uploadFiles: File[] | FileWithPath[],
            uploadFolder: boolean,
            onTotalProgress: (progress: number) => void,
            video,
            sliceFlg,
            setDisableOptionFields
        ) => {
            setDisableOptionFields(true);
            const PROGRESS_WEIGHT_FILE = 0.05;
            const PROGRESS_WEIGHT_BYTE = 0.95;
            controller = new AbortController();
            const signal = controller.signal;
            const folderIds: {
                [path: string]: Promise<string>;
            } = {};
            const itemsByType: {
                [fileId: string]: string;
            } = {};
            let queue = new Queue({ concurrency: 5, signal });

            const getDstFolderId = async (components: string[]) => {
                if (!components.length) {
                    return rootFolderId;
                }

                const path = components.join('/');
                let p = folderIds[path];
                if (!p) {
                    // フォルダIDを取得するまでダミーの値を入れておく
                    p = (async () => {
                        const folderName = components.pop();
                        const parentFolderId = await getDstFolderId(components);
                        const resp = await FoldersAPI.post({
                            parentFolderId,
                            folderName,
                        });
                        const newFolderId = resp?.data?.folderId;
                        if (!newFolderId) {
                            throw new Error(
                                resp.message || 'フォルダの作成に失敗しました'
                            );
                        }
                        return newFolderId;
                    })();
                    folderIds[path] = p;
                }
                return await p;
            };

            // アップロード対象ファイルでない場合は処理を実施しない
            uploadFiles = uploadFiles.filter((file) =>
                FilesAPI.isUploadable(file)
            );
            const totalSize = getTotalUploadFileSize(uploadFiles);
            const indexStep = PROGRESS_WEIGHT_FILE / uploadFiles.length;
            const sizeStep = PROGRESS_WEIGHT_BYTE / totalSize;
            const filesProgress = uploadFiles.map(
                (file: File | FileWithPath) => ({
                    progress: 0,
                    file,
                    step: indexStep + sizeStep * file.size,
                    type: file.type,
                })
            );
            for (const fileProgress of filesProgress) {
                const { file } = fileProgress;
                queue.add(async () => {
                    let folderId: string = rootFolderId;

                    if (uploadFolder) {
                        const path =
                            file.webkitRelativePath || file['path'] || '';
                        if (!path) {
                            throw new Error('フォルダパスの取得に失敗しました');
                        }
                        // パスを分割する
                        const components = path
                            .split(/[/\\]/)
                            .filter((text) => text);
                        folderId = await getDstFolderId(
                            components.slice(0, -1)
                        );
                    }

                    const PART_UPLOAD = 0.85;
                    const PART_CHECK = 0.15;

                    const sliceOptions: MoveSliceOptions =
                        sliceFlg && file.type.includes('video')
                            ? {
                                  slice: video.sliceTime,
                                  minDistanceInM: video.useSliceM
                                      ? video.sliceM
                                      : undefined,
                                  removeVideo: video.removeVideo ? true : false,
                              }
                            : null;

                    const { data: fileId } = await mutateAsync(
                        FilesAPI.uploadFile,
                        { folderId, file, sliceOptions },
                        {
                            signal,
                            onProgress({ progress }) {
                                fileProgress.progress = PART_UPLOAD * progress;
                                const totalProgress = filesProgress.reduce(
                                    (total, { progress, step }) =>
                                        total + step * progress,
                                    0
                                );
                                onTotalProgress(totalProgress);
                            },
                        }
                    );
                    console.log('waitForUpload');
                    queue.add(
                        bgAsync(async () => {
                            await FilesAPI.waitForUpload(
                                fileId,
                                (progress) => {
                                    fileProgress.progress =
                                        PART_UPLOAD + PART_CHECK * progress;
                                    const totalProgress = filesProgress.reduce(
                                        (total, { progress, step }) =>
                                            total + step * progress,
                                        0
                                    );
                                    onTotalProgress(totalProgress);
                                },
                                signal
                            );
                        })
                    );
                });
            }
            await queue.finish();
            checkAbort(signal);

            onTotalProgress(1.0);
        },
    };
})();

export const FileUploadBox = (props: FileUploadBoxProp) => {
    const {
        className,
        okText = 'アップロード',
        cancelText = 'キャンセル',
        folderId,
        isSwhoAlert,
        uploadFolder = false,
        onDone = () => {},
        onCancel,
        onChange = () => {},
    } = props;

    const [uploadFiles, setUploadFiles] = React.useState<
        File[] | FileWithPath[]
    >([]);
    const [errors, setErrors] = React.useState<Array<string>>([]);
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [percent, setPercent] = React.useState<number>(0);
    const [showConfirm, setShowConfirm] = React.useState<boolean>(false);

    const config = useUserConfig();
    const [video, setVideo] = useState(config.get().video);

    const [disableOptionFields, setDisableOptionFields] =
        useState<boolean>(false);

    const optionFields = useLocalConfigVideoFields({
        video,
        setVideo,
        disable: disableOptionFields,
        showSliceSwitch: false,
    });

    useMemo(() => {
        video.removeVideo = true;
        setVideo(video);
    }, []);

    const [sliceFlg, setSliceFlg] = React.useState<boolean>(video.sliceFlg);

    const onDrop = React.useCallback(
        (files: FileWithPath[]) => {
            console.log('selectFiles', files);
            let selectedFiles: FileWithPath[] = Array.from(files ?? []).filter(
                (file) => FilesAPI.isUploadable(file)
            );
            if (uploadFolder) {
                selectedFiles = selectedFiles.filter(({ path }) =>
                    /^\/.+\//.test(path ?? '')
                );
            }
            setUploadFiles(selectedFiles);
        },
        [uploadFolder]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: true,
        accept: (() => {
            const retVal = {};
            UPLOADABLE_CONTENT_TYPES.forEach((type) => {
                retVal[type] = [];
            });
            return retVal;
        })(),
    });

    const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        console.log('selectFiles', files);
        setUploadFiles(
            Array.from(files ?? []).filter((file) =>
                FilesAPI.isUploadable(file)
            )
        );
        onChange(event);
    };

    const _onClick = React.useCallback(() => {
        console.log('uploading', { folderId, uploadFiles });

        setErrors([]);
        setProcessing(true);
        setPercent(0);
        uploadFileController
            .start(
                folderId,
                uploadFiles,
                uploadFolder,
                setPercent,
                video,
                sliceFlg,
                setDisableOptionFields
            )
            .then(() => {
                setProcessing(false);
                onDone();
            })
            .catch((err) => {
                console.warn(err);
                setErrors((prevState) => [...prevState, getErrorMessage(err)]);
                setProcessing(false);
            });
    }, [folderId, uploadFiles, video, sliceFlg]);

    const showVideoConfig = React.useMemo(() => {
        return !!uploadFiles.find((file) => /^video\/./.test(file.type));
    }, [uploadFiles]);

    const ready = uploadFiles.length >= 1;

    return (
        <div className="atomic dialog-backdrop">
            <div className={classNames('atomic file-upload-box', className)}>
                {isSwhoAlert && <div className="collaborator-alert"></div>}
                <div className="file-btn-line">
                    {uploadFolder ? (
                        <input
                            type="file"
                            multiple
                            accept={UPLOADABLE_CONTENT_TYPES.join(',')}
                            onChange={_onChange}
                            disabled={processing}
                            /* @ts-expect-error */
                            directory=""
                            webkitdirectory=""
                        />
                    ) : (
                        <input
                            type="file"
                            multiple
                            accept={UPLOADABLE_CONTENT_TYPES.join(',')}
                            onChange={_onChange}
                            disabled={processing}
                        />
                    )}
                </div>
                <div className="content">
                    <div className="dropin-box" {...getRootProps()}>
                        <p>
                            {uploadFolder ? 'フォルダ' : 'ファイル'}をドロップ
                        </p>
                    </div>
                </div>
                {uploadFiles.length > 0 && (
                    <ul className="files">
                        {uploadFiles.map((f, i) => {
                            // console.log(f);
                            const { name } = f;
                            return (
                                <li key={i}>
                                    <FileIcon />
                                    {name}
                                </li>
                            );
                        })}
                    </ul>
                )}
                {errors.length > 0 && (
                    <ul className="errors">
                        {errors.map((err, i) => {
                            console.log(err);
                            return (
                                <li className="error" key={i}>
                                    {err}
                                </li>
                            );
                        })}
                    </ul>
                )}
                {showVideoConfig && (
                    <div className="options">
                        <SlideSwitch
                            defaultValue={sliceFlg}
                            name={'動画分割'}
                            text="動画を分割する"
                            _onChange={setSliceFlg}
                            disabled={processing}
                        />
                        {sliceFlg && optionFields}
                    </div>
                )}
                <div className="controls">
                    <Button
                        className="btn cancel"
                        onClick={() => {
                            if (processing) {
                                uploadFileController.cancel(
                                    setDisableOptionFields
                                );
                            } else {
                                onCancel();
                            }
                        }}
                    >
                        {cancelText}
                    </Button>
                    <Button
                        className={classNames('btn ok', { primary: ready })}
                        icon="fa-solid fa-upload"
                        disabled={!ready || processing}
                        onClick={() => {
                            if (
                                getTotalUploadFileSize(uploadFiles) >
                                SHOW_UPLOAD_ALERT_SIZE
                            ) {
                                setShowConfirm(true);
                            } else {
                                _onClick();
                            }
                        }}
                    >
                        {okText}
                    </Button>
                </div>
                {showConfirm && (
                    <DialogBox
                        title="確認"
                        onOK={() => {
                            setShowConfirm(false);
                            _onClick();
                        }}
                        onCancel={() => setShowConfirm(false)}
                    >
                        <p>
                            大量のファイルやサイズの大きなファイルをアップロードすると、
                            <br />
                            アップロード処理中にセッションがタイムアウトして、
                            <br />
                            アップロードが失敗する可能性があります。
                        </p>
                        <p>
                            アップロードするファイルを精査してサイズを抑制してください。
                        </p>
                        <br />
                        <p>ファイルのアップロードを継続しますか？</p>
                    </DialogBox>
                )}
                {processing && <ProgressBar percent={percent} />}
            </div>
        </div>
    );
};

export type CancelDialogProps = DialogProps<
    { projectId: string; taskId: string },
    void
>;
export const CancelTaskDialog = (opts: CancelDialogProps) => {
    const projectTask = useGet(
        projectApi.get.projectTask(opts.options.projectId)
    );
    if (!projectTask.data) {
        return <></>;
    }
    if (projectTask.data?.data?.status == TaskState.queueing) {
        return (
            <DialogBox title="確認" {...opts}>
                タスクを停止します
            </DialogBox>
        );
    }
    return (
        <DialogBox title="確認" {...opts}>
            実行中のタスクを強制的に停止した場合、AIの実行結果が正しく得られなくなる可能性があります。
            <br />
            本当にタスクを停止してよいですか？
        </DialogBox>
    );
};
