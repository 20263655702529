import { DependencyList, useEffect, useState } from 'react';
import { APIGetRequest, UseGetResponse } from './useApi';

export function useNewData<Data, Error>(
    apiGet: UseGetResponse<APIGetRequest<Data> | null, Error>,
    fn: (data: NonNullable<Data>) => boolean | null | undefined | void,
    deps: DependencyList
) {
    const [prevKey, setPrevKey] = useState<string>();
    useEffect(() => {
        if (!apiGet) return;
        const { data, isLoading, key } = apiGet;
        if (isLoading) return;
        if (key === prevKey) return;
        if (!data) return;
        if (fn(data)) {
            setPrevKey(key);
        }
    }, [prevKey, apiGet?.data, apiGet?.isLoading, apiGet?.key, ...deps]);
}
