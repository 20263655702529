import { createMutation, simpleGet } from '../hooks/useApi';
import {
    CreateTenantRequest,
    TenantModel,
    TenantOptionsModel,
    UpdateTenantRequest,
} from '../models/TenantModel';
import { HttpClient } from '../utils/HttpUilts';

export const tenantApi = {
    get: {
        getTenantsForSuperAdmin: simpleGet<TenantModel[]>`/tenants`,
        getTenantForSuperAdmin: (tenantId: string) =>
            simpleGet<TenantModel>`/tenants/${tenantId}`,
        getTenantStatus: simpleGet<TenantModel>`/tenant/status`,
    },
    mutate: {
        createTenantForSuperAdmin: createMutation<
            CreateTenantRequest,
            {
                status: string;
                data: TenantModel;
            }
        >(async ({ authenticated }, data, opts) =>
            authenticated.post('/tenant/create', data, opts)
        ),
        updateTenantForAdmin: createMutation<
            UpdateTenantRequest,
            {
                status: string;
                data: TenantModel;
            }
        >(async ({ authenticated }, data, opts) =>
            authenticated.put('/tenant/update', data, opts)
        ),
    },
};
//*** */

export const getTenantsForSuperAdmin = (): Promise<{ data: TenantModel[] }> => {
    return HttpClient.get('/tenants');
};

export const createTenantForSuperAdmin = (data: CreateTenantRequest) => {
    return HttpClient.post('/tenant/create', data);
};

export const updateTenantForAdmin = (data: UpdateTenantRequest) => {
    return HttpClient.put('/tenant/update', data);
};

export const getTenantForSuperAdmin = (tenantId: string) => {
    return HttpClient.get(`/tenant/${encodeURIComponent(tenantId)}`);
};
//*** */
export const getTenantAiTypes = (tenantId: string) => {
    return HttpClient.get(
        `/admin/ai-analysis-types-tenants/${encodeURIComponent(tenantId)}`
    );
};

export type GetTenantOptionModel = {
    tenantId: string;
    category: string;
    keys?: Array<string>;
    array?: Array<string>;
};

export const getTenantOptions = (params: GetTenantOptionModel) => {
    return HttpClient.get(
        `/admin/tenant/${encodeURIComponent(params.tenantId)}/options`,
        params
    );
};
export const getIsExternalApi = () => {
    return HttpClient.get(`/tenant/options/isExternalApi`);
};

export const getTenantForKintoneAppFields = (
    tenantId: string,
    appId: number
) => {
    return HttpClient.get(
        `/admin/tenant/${encodeURIComponent(
            tenantId
        )}/options/kintone/${encodeURIComponent(appId)}/fields`
    );
};

export const getTenantForKintoneApp = (tenantId: string, appId: number) => {
    return HttpClient.get(
        `/admin/tenant/${encodeURIComponent(
            tenantId
        )}/options/kintone/app/${encodeURIComponent(appId)}`
    );
};

export const putTenantOptions = (
    tenantId: string,
    options: TenantOptionsModel | null
) => {
    return HttpClient.put(`/admin/tenant/options`, {
        tenantId: tenantId,
        options: options,
    });
};
