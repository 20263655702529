import React, { useCallback, useState } from 'react';
import { DialogBox } from '../atomic/dialog';
import { DialogProps } from '../../hooks/useDialog';
import { LocalVideoConfig } from '../config/LocalConfig';
import { StartFolderAiProcessRequest } from '../../models/AiProcessModel';
import { useLocalConfigVideoFields } from '../config/useLocalConfigVideoFields';
import { CheckBox } from '../atomic/switches';

export type StartFolderAiDialogProps = DialogProps<
    LocalVideoConfig,
    Omit<StartFolderAiProcessRequest, 'projectId'>
>;
export const StartFolderAiDialog = ({
    options,
    onOK: onCommit,
    ...rest
}: StartFolderAiDialogProps) => {
    const [video, setVideo] = useState(options);
    const [resume, setResume] = useState(true);
    const onOK = useCallback(
        () =>
            onCommit({
                frames: {
                    slice: video.sliceTime,
                    minDistanceInM: video.useSliceM ? video.sliceM : undefined,
                },
                removeVideo: video.removeVideo ? 1 : 0,
                resume: resume ? 1 : 0,
            }),
        [onCommit, video, resume]
    );
    const optionFields = useLocalConfigVideoFields({
        video,
        setVideo,
        disable: rest.disabled,
        showSliceSwitch: false,
    });
    return (
        <DialogBox title="確認" {...rest} onOK={onOK}>
            ファイル数が多い場合、AIの実行完了までに数時間程度かかる可能性があります。
            <br />
            処理を継続してよいですか？
            <hr />
            <h3>動画分割設定</h3>
            {optionFields}
            <hr />
            <h3 style={{ marginBottom: '16px' }}>AI実行設定</h3>
            <CheckBox
                label="続きから実行する"
                name="resume"
                checked={resume}
                _onChange={setResume}
            />
        </DialogBox>
    );
};
