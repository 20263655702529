import React, { createContext, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FolderIcon } from './icons';
import { useGet } from '../../hooks/useApi';
import { safeUrl } from '../../utils/safeUrl';
import { FilesAPI } from '../../APIs/filesApi';

export type NavigateToItemProps = {
    fileId?: string;
    sourceId?: string;
    videoTimeSeconds?: number;
    replace?: boolean;
};

export type NavigateToItemHandler = (props: NavigateToItemProps) => void;

const navigateNoWhere = () => {
    console.warn('Navigate to Item not setup!');
};

const navigateCtx = createContext<NavigateToItemHandler>(navigateNoWhere);

export const NavigateToItemContext = navigateCtx.Provider;

export const useNavigateToItem = () => useContext(navigateCtx);

export type FolderLinkProps = {
    folderId: string;
};

export const getFolderLink = (folderId: string) =>
    safeUrl`/app/folderlist/${folderId}`;

export const FolderLink = ({ folderId }: FolderLinkProps) => {
    const folder = useGet(FilesAPI.item(folderId));
    return (
        <Link to={getFolderLink(folderId)}>
            <FolderIcon /> {folder.data?.data.name ?? '...'}
        </Link>
    );
};
