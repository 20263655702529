import React, { FC, useState } from 'react';
import {
    GroupFolderModel,
    GroupFolderRequest,
    GroupModel,
    userApi,
} from '../../APIs/userApi';
import { DialogBox } from '../atomic/dialog';
import { Loading } from '../atomic/effects';
import { getAsync, mutateAsync, useGet } from '../../hooks/useApi';
import { FoldersAPI } from '../../APIs/foldersApi';
import { FilesAPI } from '../../APIs/filesApi';
import { FolderModel } from '../../models/FolderModel';
import { ItemModel } from '../../models/ItemModel';
import { CheckBox } from '../atomic/switches';

type Prop = {
    groupId: string;
    isOpen: boolean;
    setIsOpen: Function;
};

export const GroupFolderEditModal: FC<Prop> = ({
    groupId,
    isOpen,
    setIsOpen,
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const ref = React.useRef<DialogBox>();
    const [groupFolders, setGroupFolders] = useState<Array<GroupFolderModel>>(
        []
    );
    const [rootFolders, setRootFolders] = useState<Array<ItemModel>>([]);

    const updateGroupFolder = () => {
        getAsync(userApi.get.groupFolders(groupId))
            .then((resp) => {
                setGroupFolders(resp.data);
            })
            .catch((err) => {})
            .finally(() => {});
    };

    React.useEffect(() => {
        if (isOpen) {
            setLoading(true);

            setRootFolders([]);
            updateGroupFolder();

            function getRootFolders(offset) {
                getAsync(
                    FilesAPI._items({
                        parentFolderId: undefined,
                        idsOnly: false,
                        visualFilesOnly: true,
                        file: false,
                        folder: true,
                        frame: false,
                        limit: 100,
                        offset: offset,
                    })
                ).then((resp) => {
                    if (resp.data.length) {
                        setRootFolders((folders) => [...folders, ...resp.data]);
                        getRootFolders(resp.pagination.nextOffset);
                    } else {
                    }
                });
            }
            setLoading(false);
            getRootFolders(0);
        }
    }, [groupId, isOpen]);

    React.useEffect(() => {
        console.log(groupFolders);
    });

    const onOK = React.useCallback(async () => {
        setIsOpen(false);
    }, []);

    const isGroupFolder = (folder) => {
        return groupFolders.some((group) => group.folderId == folder.folderId);
    };

    const onChange = async (folder) => {
        setLoading(true);
        const request: GroupFolderRequest = {
            folderId: folder.folderId,
            groupId: groupId,
        };
        const deleteGroupFolder = groupFolders.find(
            (group) => folder.folderId === group.folderId
        );
        if (!deleteGroupFolder) {
            const resp = await mutateAsync(
                userApi.mutate.createGroupFolder(),
                request
            )
                .catch((err) => {
                    console.warn(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            await userApi.delete.groupFolder(deleteGroupFolder.groupFolderId);
        }
        updateGroupFolder();

        setLoading(false);
    };
    return (
        <>
            <div>
                {isOpen && (
                    <DialogBox
                        className="userGroupFolderDialog"
                        title={'グループフォルダの変更'}
                        onOK={onOK}
                        okText="閉じる"
                        ref={ref}
                        disabledCancel={true}
                        onCancel={function (): void {
                            throw new Error('Function not implemented.');
                        }}
                    >
                        <ul>
                            {rootFolders.map((folder) => {
                                return (
                                    <li key={folder.folderId}>
                                        <CheckBox
                                            label={folder.name}
                                            name="aspect-enabled"
                                            checked={isGroupFolder(folder)}
                                            _onChange={() => onChange(folder)}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </DialogBox>
                )}
            </div>
            {loading && <Loading />}
        </>
    );
};
