import React, { useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ErrorDisplay, NotFound } from './Errors';
import { projectApi } from '../APIs/projectApi';
import { getAsync, useGet } from '../hooks/useApi';
import { SpinnerIcon, FolderIcon, ExportIcon } from './atomic/icons';
import { Table } from './atomic/table';
import { Loading } from './atomic/effects';
import classNames from 'classnames';
import { useClickable } from '../hooks/useClickable';
import { useReportDownloader } from './ReportDownloader';
import { CSVHeader, useCSV } from '../hooks/useCSV';
import { getProjectItemLink } from './Project';
import { createCSVBlob, CSVModel, Header } from '../utils/csv';
import { FolderItemModel, isFolderItem } from '../models/ItemModel';
import { FilesAPI } from '../APIs/filesApi';

export interface ProjectHeader {
    text: string;
    index: number;
    sortOrder?: 'asc' | 'desc';
    toggleSort: () => void;
}

const SortableDiv = ({ header }) => {
    const { sortOrder, text: children } = header;
    const props = useClickable(() => header.toggleSort(), [header.toggleSort]);
    const sortName = sortOrder ? `sorted` : null;

    return (
        <div className={classNames('sortable', sortName, sortOrder)} {...props}>
            {children}
        </div>
    );
};

interface ReportData {
    isLoading: boolean;
    header: Array<string>;
    rows: Array<string>;
}

const _Report = ({
    projectId,
    folder,
    projectName,
    folderId,
}: {
    projectId: string;
    folder: FolderItemModel;
    projectName: string;
    folderId: string;
}) => {
    const [report, setReport] = React.useState<ReportData>({
        isLoading: true,
        header: [],
        rows: [],
    });

    const [sortedBy, setSortedBy] = React.useState<{
        index: number;
        order: 'asc' | 'desc';
    }>();
    const limit = 100;
    React.useEffect(() => {
        const getReport = async ({
            projectId: projectId,
            skipEmpty,
            includeUrl,
            limit,
            offset,
            setReport,
        }) => {
            const _report = await getAsync(
                projectApi.get.report({
                    projectId,
                    skipEmpty,
                    includeUrl,
                    limit,
                    offset,
                })
            );
            const isLoading =
                _report.pagination.total > _report.pagination.nextOffset;
            setReport((prev) => {
                return {
                    ...prev,
                    isLoading: isLoading,
                    header: _report.data.headings,
                    rows: [...prev.rows, ..._report.data.data],
                };
            });
            if (isLoading) {
                getReport({
                    projectId: projectId,
                    skipEmpty,
                    includeUrl,
                    limit,
                    offset: _report.pagination.nextOffset,
                    setReport,
                });
            }
        };

        getReport({
            projectId: projectId,
            skipEmpty: false,
            includeUrl: true,
            limit,
            offset: 0,
            setReport,
        });
    }, [projectId]);

    const columns = useMemo(
        () =>
            report.header
                .filter((title, index) => {
                    return index === 2 ? false : true;
                })
                .map((title, index) => ({
                    id: `column-${index}`,
                    header: () => {
                        const header: ProjectHeader = {
                            text: title,
                            index: index,
                            sortOrder:
                                sortedBy && sortedBy.index == index
                                    ? sortedBy?.order
                                    : undefined,
                            toggleSort: () => {
                                setSortedBy((sortedBy) => {
                                    if (!sortedBy || sortedBy.index !== index) {
                                        return {
                                            index,
                                            order: 'asc',
                                        };
                                    }
                                    if (sortedBy.order === 'asc') {
                                        return {
                                            index,
                                            order: 'desc',
                                        };
                                    }
                                    return undefined;
                                });
                            },
                        };
                        return <SortableDiv header={header} />;
                    },
                    cell:
                        index === 0
                            ? (d) => {
                                  const { row } = d.row.original;
                                  const fileId = row[index];
                                  return (
                                      <Link
                                          to={getProjectItemLink({
                                              folderId,
                                              projectId,
                                              fileId,
                                          })}
                                      >
                                          {fileId}
                                      </Link>
                                  );
                              }
                            : index === 4
                            ? (d) => {
                                  const { row } = d.row.original;
                                  const report = row[index];
                                  return (report ?? '')
                                      .split(/\r?\n/g)
                                      .map((text, index) => (
                                          <div key={index}>{text}</div>
                                      ));
                              }
                            : (d) => {
                                  const val = d.row.original.row[index];
                                  if (Number.isNaN(val)) {
                                      return <div></div>;
                                  }
                                  return <div>{val === '-' ? '0' : val}</div>;
                              },
                })),
        [report.header, sortedBy]
    );

    const data = useMemo(
        () =>
            report.rows
                .map((row) => {
                    const getVal = (row) => {
                        return row
                            .filter((value, index) => {
                                return index === 2 ? false : true;
                            })
                            .map((value, index) => {
                                const header = report.header[index];
                                if (
                                    index === 2 ||
                                    index === 3 ||
                                    header.endsWith('の数') ||
                                    header.endsWith('の面積')
                                ) {
                                    if (value === '' || value === '-') return 0;
                                    return parseFloat(value);
                                }
                                return value;
                            });
                    };
                    return {
                        trClass: 'file',
                        row: getVal(row),
                    };
                })
                .sort((a, b) => {
                    if (!sortedBy) {
                        return 0;
                    }
                    const dataType = typeof a.row[sortedBy.index];
                    if (sortedBy) {
                        if (sortedBy.order == 'asc') {
                            return a.row[sortedBy.index] > b.row[sortedBy.index]
                                ? 1
                                : -1;
                        }
                        return b.row[sortedBy.index] > a.row[sortedBy.index]
                            ? 1
                            : -1;
                    }

                    return 0;
                }),
        [report.rows, sortedBy]
    );
    const activeFiles = useMemo(
        () =>
            report.rows.map((row) => ({
                fileId: row[0],
                fileName: row[1],
                fileUrl: row[2],
            })),
        [report.rows]
    );
    const downloader = useReportDownloader();

    const csvBlob = useMemo<Blob>(() => {
        const reportCsv: CSVModel<string> = {
            header: !report.isLoading
                ? report.header.map((text, id) => {
                      return { text, id: id.toString() } as Header;
                  })
                : [],
            list: !report.isLoading
                ? report.rows.map((line) => {
                      return line.map((val) => {
                          return val;
                      });
                  })
                : [],
        };
        return createCSVBlob(reportCsv);
    }, [report.rows]);
    return (
        <div className="app-main app-report">
            <h2>{projectName} の統計情報</h2>
            {folderId != '' && (
                <div>
                    <Link to={`/app/folderlist/${folderId}`}>
                        {report.isLoading ? <SpinnerIcon /> : <FolderIcon />}{' '}
                        フォルダページへ戻る
                    </Link>
                    <Link
                        to="#"
                        onClick={() =>
                            !report.isLoading &&
                            downloader.show({
                                folder,
                                csvBlob: csvBlob,
                                projectName,
                                activeFiles,
                            })
                        }
                        style={{ marginLeft: '2em' }}
                    >
                        {report.isLoading ? <SpinnerIcon /> : <ExportIcon />}{' '}
                        ダウンロード
                    </Link>
                </div>
            )}
            <div className="table-wrapper">
                <Table columns={columns} data={data} className="report-table" />
            </div>
            {downloader.dialog}
        </div>
    );
};

export const Report = () => {
    const { projectId } = useParams();
    const project = useGet(
        projectId ? projectApi.get.project(projectId) : null
    );
    const projectName: string = project?.data?.data?.name ?? '';
    const folderId: string = project?.data?.data?.folderId ?? '';
    const folder = useGet(folderId ? FilesAPI.item(folderId) : null);

    if (!projectId) {
        return <NotFound />;
    }
    if (folder?.isLoading || project?.isLoading) {
        return <Loading />;
    }
    const error = project?.error;
    if (error) {
        return <ErrorDisplay error={error} />;
    }
    if (!folder?.data?.data?.folder) {
        return (
            <ErrorDisplay
                error={new Error('フォルダ情報の取得に失敗しました')}
            />
        );
    }
    if (!isFolderItem(folder.data.data)) {
        return (
            <ErrorDisplay error="フォルダーの代わりにファイルをロードしました。" />
        );
    }
    return (
        <_Report
            projectId={projectId}
            folder={folder.data.data}
            projectName={projectName}
            folderId={folderId}
        />
    );
};
