import React, { FC, useState } from 'react';
import { TextField } from '../atomic/forms';
import { DialogBox } from '../atomic/dialog';
import { Loading } from '../atomic/effects';
import { userApi } from '../../APIs/userApi';
import { mutateAsync, useGet } from '../../hooks/useApi';

type Prop = {
    groupId?: string;
    isOpen: boolean;
    setIsOpen: Function;
    mutate: Function;
};

export const GroupEditModal: FC<Prop> = ({
    groupId,
    isOpen,
    setIsOpen,
    mutate,
}) => {
    const [groupName, setGroupName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const ref = React.useRef<DialogBox>();

    const { data: group } = useGet(userApi.get.group(groupId));

    React.useEffect(() => {
        if (group) {
            setGroupName(group.data.name);
        } else {
            setGroupName('');
        }
    }, [group]);

    const onOK = React.useCallback(async () => {
        setLoading(true);

        if (group?.data.groupId) {
            const resp = await mutateAsync(
                userApi.mutate.updateGroup(group.data.groupId),
                {
                    name: groupName,
                }
            )
                .catch((err) => {
                    console.warn(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            const resp = await mutateAsync(userApi.mutate.createGroup, {
                name: groupName,
            })
                .catch((err) => {
                    console.warn(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        mutate();
        setGroupName('');
        setLoading(false);
        setIsOpen(false);
    }, [groupName]);
    const onCancel = React.useCallback(() => {
        setIsOpen(false);
    }, []);
    const title = groupId ? 'グループ編集' : 'グループ追加';
    return (
        <>
            <div>
                {isOpen && (
                    <DialogBox
                        title={title}
                        onOK={onOK}
                        onCancel={onCancel}
                        ref={ref}
                    >
                        <ul className="fields">
                            <li>
                                <TextField
                                    label="グループ名"
                                    _onChange={setGroupName}
                                    value={groupName}
                                    required={true}
                                />
                            </li>
                        </ul>
                    </DialogBox>
                )}
            </div>
            {loading && <Loading />}
        </>
    );
};
