import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { TenantModel } from '../../../models/TenantModel';
import { Table, columnDefiner } from '../../atomic/table';
import { reformat } from '../../../utils/DateUtils';
import { TenantEdit } from './TenantEdit';
import { ConfigIcon } from '../../atomic/icons';
import { TenantFormDialog } from './TenantFormDialog';
import { comma } from '../../../utils/FormUtils';

interface TenantData extends TenantModel {
    trClass: string;
}
const definer = columnDefiner<TenantData>();

const columns = [
    definer.accessor('tenantId', {
        header: 'テナントID',
        cell: (d) => {
            const { tenantId } = d.row.original;

            return (
                <>
                    <div className="tenant id">
                        <span>{tenantId}</span>
                    </div>
                </>
            );
        },
    }),
    {
        header: 'テナント名',
        cell: (d) => {
            const { openFormModal } = d.table.options;
            const { name, upload } = d.row.original;
            return (
                <>
                    <Link
                        to={`#`}
                        onClick={() => {
                            openFormModal(d.row.original);
                        }}
                    >
                        {name} {!!!upload && <>{'(アップロード不可)'}</>}
                    </Link>
                </>
            );
        },
    },
    {
        header: 'ストレージ(使用/最大)',
        cell: (d) => {
            const { availableStorageSize, size } = d.row.original;
            return `${comma(size)} GB/${comma(availableStorageSize)} GB`;
        },
    },
    {
        header: 'ストレージ利用率(%)',
        cell: (d) => {
            const { usage } = d.row.original;
            return `${usage}`;
        },
    },
    {
        header: 'ストレージ容量算出日',
        cell: (d) => {
            const { finishedAt } = d.row.original;
            return `${reformat(finishedAt)}`;
        },
    },
    {
        header: 'ユーザー数',
        cell: (d) => {
            const { numberOfUsers } = d.row.original;
            return `${numberOfUsers}`;
        },
    },
    {
        header: '最大ユーザー数',
        cell: (d) => {
            const { maxUserLicenses } = d.row.original;
            return `${maxUserLicenses}`;
        },
    },
    {
        header: '更新日',
        cell: (d) => {
            const { updatedAt } = d.row.original;
            return `${reformat(updatedAt)}`;
        },
    },
    {
        header: '作成日',
        cell: (d) => {
            const { createdAt } = d.row.original;
            return `${reformat(createdAt)}`;
        },
    },
    {
        header: 'オプション',
        cell: (d) => {
            const { openModal } = d.table.options; // ToDo 型の解決
            const { tenantId } = d.row.original;

            return (
                <>
                    <Link
                        to={`#`}
                        onClick={() => {
                            openModal(tenantId);
                        }}
                    >
                        <ConfigIcon />
                    </Link>
                </>
            );
        },
    },
];

type Prop = {
    tenants: TenantModel[];
    mutate: any;
};
export const TenantList: FC<Prop> = ({ tenants, mutate }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const [isFormModalOpen, setFormModalOpen] = React.useState<boolean>(false);

    const [tenantId, setTenantId] = React.useState<string | undefined>(
        undefined
    );
    const [currentTenant, setCurrentTenant] =
        React.useState<TenantModel | null>(null);
    const openModal = (tenantId: string) => {
        setTenantId(tenantId);
        setIsOpen(true);
    };
    const openFormModal = (tenant: TenantModel) => {
        setFormModalOpen(true);
        setCurrentTenant(tenant);
    };

    React.useEffect(() => {
        if (!isFormModalOpen) mutate();
    }, [isFormModalOpen]);

    const options = {
        openModal,
        openFormModal,
    };
    return (
        <>
            <div className="edit-forms">
                {tenantId != undefined && (
                    <TenantEdit
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        tenantId={tenantId}
                    />
                )}
                {isFormModalOpen && currentTenant && (
                    <TenantFormDialog
                        isOpen={isFormModalOpen}
                        setIsOpen={setFormModalOpen}
                        tenant={currentTenant}
                    />
                )}
                {tenants.length ? (
                    <Table
                        className="tenant-table"
                        data={tenants}
                        columns={columns}
                        options={options}
                    />
                ) : (
                    ''
                )}
            </div>
        </>
    );
};
