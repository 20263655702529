import React, { FC, useState } from 'react';
import { DialogBox } from '../atomic/dialog';
import { Loading } from '../atomic/effects';
import { User, useTenant } from '../../contexts/TenantContext';
import { getAsync, mutateAsync, useGet } from '../../hooks/useApi';
import { GroupUserModel, GroupUserRequest, userApi } from '../../APIs/userApi';
import { CheckBox } from '../atomic/switches';

type Prop = {
    groupId: string;
    isOpen: boolean;
    setIsOpen: Function;
};

export const GroupUserEditModal: FC<Prop> = ({
    groupId,
    isOpen,
    setIsOpen,
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const ref = React.useRef<DialogBox>();
    const [users, setUsers] = React.useState<Array<User>>([]);
    const [groupUsers, setGroupUsers] = React.useState<Array<GroupUserModel>>(
        []
    );

    const { tenantUsers } = useTenant();

    React.useEffect(() => {
        const users = tenantUsers.filter((user) => {
            return user.role === 'User';
        });
        setUsers(users);
    }, [tenantUsers]);

    const onOK = React.useCallback(() => {
        setIsOpen(false);
        setGroupUsers([]);
    }, []);

    const onChange = async (user) => {
        setLoading(true);
        const request: GroupUserRequest = {
            userId: user.userId,
            groupId: groupId,
        };
        const deleteUser = groupUsers.find(
            (delUser) => user.userId === delUser.userId
        );
        if (!deleteUser) {
            const resp = await mutateAsync(
                userApi.mutate.createGroupUser(),
                request
            )
                .catch((err) => {
                    console.warn(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            await userApi.delete.groupUser(deleteUser.groupUserId);
        }
        const resp = await getAsync(userApi.get.groupUsers(groupId));
        resp?.data ? setGroupUsers(resp?.data) : setGroupUsers([]);

        setLoading(false);
    };

    React.useEffect(() => {
        setLoading(true);
        getAsync(userApi.get.groupUsers(groupId))
            .then((resp) => {
                setGroupUsers(resp.data);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    }, [groupId, isOpen]);

    const viewUser = (user: User) => {
        if (user.familyName == null || user.givenName == null) {
            return user.email;
        }
        return user ? `${user.familyName} ${user.givenName}` : 'unknown';
    };

    const isGroupUser = (user: User) => {
        return groupUsers.some((groupUser) => groupUser.userId == user.userId);
    };

    return (
        <>
            <div>
                {isOpen && (
                    <DialogBox
                        className="userGroupDialog"
                        title={'グループユーザーの変更'}
                        onOK={onOK}
                        okText="閉じる"
                        ref={ref}
                        disabledCancel={true}
                    >
                        <ul className="users">
                            {users.map((user) => {
                                return (
                                    <li key={user.userId}>
                                        <CheckBox
                                            label={viewUser(user)}
                                            name="aspect-enabled"
                                            checked={isGroupUser(user)}
                                            _onChange={() => onChange(user)}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </DialogBox>
                )}
            </div>
            {loading && <Loading />}
        </>
    );
};
