import { TaskID } from '../utils/ServerClientModel';

export enum TaskState {
    running = 'RUNNING',
    success = 'SUCCESS',
    error = 'ERROR',
    queueing = 'QUEUEING',
}

export interface TaskProgress {
    state: TaskState;
    percentDone: number;
    error?: Error;
}
export interface AiTaskRequest {
    type?: 'ai' | 'folder' | 'video';
    ai: string;
    files?: Array<{
        file_id: string;
        mime_type: string;
    }>;
}

export type TaskRequestJSON = AiTaskRequest;

export interface TaskModel {
    aiTaskId: TaskID;
    projectId: string;
    folderId: string;
    status: TaskState;
    createdAt: string;
    updatedAt: string;
    request: TaskRequestJSON;
    subTaskIds: string[];
    type: string;
    image: number | null;
    movie: number | null;
    AiTaskSummary?: {
        image: number;
        movie: number;
        error: number;
    };
}

export interface TaskLogModel {
    aiTaskId: TaskID;
    projectId: string;
    folderId: string;
    status: TaskState;
    createdAt: string;
    updatedAt: string;
    type: string;
    aiRunTime: number | null;
    folder?: {
        folderName: string;
        tenantId: string;
        tenant: {
            name: string;
        };
    };
    project?: {
        name: string;
        aiAnalysisTypeNameJa: string;
    };
    createdUserId: string;
}

export interface TaskSummaryModel {
    aiTaskId: string;
    image: number;
    imageCount: number;
    movie: number;
    movieCount: number;
    error: number;
    status: string;
    aiRunTime: number | null;
    tenantId: string;
    tenantName: string;
    createdUserId: string;
    folderId: string;
    folderName: string;
    aiAnalysisTypeNameJa: string;
    projectId: string;
    projectName: string;
    createdAt: string;
    updatedAt: string;
}

export interface TaskRequest {
    aiAnalysisTypeId: string;
    fileIds: string[];
    projectId: string;
}

export interface FrontEndTaskModel {
    activateTask: TaskModel;
    queue: TaskRequest[];
}

export interface BaseResult<Event extends string = string> {
    event: Event;
    aiResultId: string;
    aiTaskId: string;
    updatedAt: string;
    createdAt: string;
}

export interface StartResult extends BaseResult<'START'> {
    success: true;
}

export enum InstanceStatusType {
    stopped = 'stopped',
    pending = 'pending',
    running = 'running',
}

export interface InstanceStatusResult extends BaseResult<'INSTANCE-STATUS'> {
    success: true;
    type: InstanceStatusType;
}

export interface BaseEndResult<Success extends boolean = boolean>
    extends BaseResult<'END'> {
    success: Success;
}

export interface EndSuccessResult extends BaseEndResult<true> {}

export interface EndErrorResult extends BaseEndResult<false> {
    error: {
        type:
            | 'exit'
            | 'crash'
            | 'aws-error'
            | 'resource-kill'
            | 'internal-error'
            | 'user-cancel';
    };
}

export type EndResult = EndSuccessResult | EndErrorResult;
export interface FileUpdateResult extends BaseResult<'FILE-UPDATE'> {
    fileId: string;
    type: 'download' | 'process';
    percent: number;
}

export interface BaseFileEndResult<Success extends boolean = boolean>
    extends BaseResult<'FILE-END'> {
    fileId: string;
    success: Success;
}

export interface FileEndSuccessResult extends BaseFileEndResult<true> {}

export interface ResultFileError {
    type: string;
    message?: string;
}

export interface ResultServerError {
    message: string;
    exception: string;
}

export interface FileEndErrorResult extends BaseFileEndResult<false> {
    error: ResultFileError;
}

export type FileEndResult = FileEndSuccessResult | FileEndErrorResult;

export interface VideoEvent<Type extends string> extends BaseResult<Type> {
    fileId: string;
}

export interface VideoStartEvent extends VideoEvent<'VIDEO-START'> {}
export interface VideoMetadataEvent extends VideoEvent<'VIDEO-METADATA'> {}
export interface VideoEndEvent extends VideoEvent<'VIDEO-END'> {
    success: boolean;
}

export interface VideoFrameResult extends VideoEvent<'VIDEO-FRAME'> {
    videoTimeSeconds: number;
}
export interface ExternalTaskResult extends BaseResult<'EXTERNAL-TASK'> {}
export interface ExternalTaskError extends BaseResult<'EXTERNAL-TASK-ERROR'> {
    error: {
        message: string;
    };
}
export interface HealthCheckResult extends BaseResult<'HEALTH-CHECK'> {
    success: true;
}
export interface BaseSubTaskResult<Success extends boolean = boolean>
    extends BaseResult<'SUB-START'> {
    success: Success;
    subTaskId: string;
}

export interface SubTaskSuccessResult extends BaseSubTaskResult<true> {
    sub_task_id: string;
}

export interface SubTaskErrorResult extends BaseSubTaskResult<true> {
    error: ResultServerError;
}

export interface ClientSyntheticStartResult extends BaseResult<'REQUEST'> {}

export interface MaxCapacityResult extends BaseResult<'MAX-CAPACITY'> {
    type: 'pending';
}

export type TaskResult =
    | ClientSyntheticStartResult
    | StartResult
    | EndResult
    | FileEndResult
    | FileUpdateResult
    | VideoStartEvent
    | VideoMetadataEvent
    | VideoFrameResult
    | VideoEndEvent
    | ExternalTaskResult
    | ExternalTaskError
    | SubTaskSuccessResult
    | SubTaskErrorResult
    | InstanceStatusResult
    | MaxCapacityResult
    | HealthCheckResult;

export type TaskResultModel = TaskResult[];
