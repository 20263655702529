import React, { FC, useMemo, useState } from 'react';
import { getAsync, useGet } from '../../hooks/useApi';
import { projectApi } from '../../APIs/projectApi';
import { Button } from '@aws-amplify/ui-react';
import { TaskLogs } from './TaskLogs';
import { Loading } from '../atomic/effects';
import { IconButton } from '../atomic/buttons';
import { difMinutes, reformat } from '../../utils/DateUtils';
import { useTenant } from '../../contexts/TenantContext';
import { downloadText } from '../../utils/download';
import { useAuth } from '../../contexts/AuthContext';
import { useFetchUserSelf } from '../../hooks/useUser';
import { TenantModel } from '../../models/TenantModel';
import { getTenantsForSuperAdmin } from '../../APIs/tenants';
import { TenantStorageStatus } from '../status/TenantStorageStatus';
import { TaskLogModel, TaskSummaryModel } from '../../models/TaskModel';
import { SpinnerIcon } from '../atomic/icons';
import { ErrorContext } from '../../contexts';

type Prop = {};
export const ActivityLog: FC<Prop> = () => {
    const GET_TASK_LIMIT = 100;
    const [month, setMonth] = useState<number>(-1);
    const { getTenantUser } = useTenant();

    const [tasks, setTasks] = useState<TaskSummaryModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { setErrors } = React.useContext(ErrorContext);

    const { fetchUser } = useAuth();
    const userInfo = useFetchUserSelf(fetchUser());

    const { start, end } = useMemo(() => {
        if (month < 0) {
            return {
                start: null,
                end: null,
            };
        }
        const now = new Date();
        const nowYear = now.getFullYear();
        const nowMonth = now.getMonth();
        const startDate = new Date(nowYear, nowMonth - month, 1);
        const endDate = new Date(nowYear, nowMonth + 1 - month, 1);

        return {
            start: startDate.toLocaleString(),
            end: endDate.toLocaleString(),
        };
    }, [month]);

    const getYearMonthString = (month: number) => {
        const now = new Date();
        const t = new Date(now.getFullYear(), now.getMonth() - month, 1);

        return `${t.getFullYear()}年${t.getMonth() + 1}月`;
    };
    const { isSuperAdmin } = useAuth();

    const [tenants, setTenants] = React.useState<
        Array<TenantModel> | undefined
    >(undefined);

    const download = () => {
        if (!tasks.length) {
            return;
        }
        const csvData = tasks.map((r) => {
            const user = getTenantUser(r.createdUserId);
            const userName = user
                ? `${user.familyName} ${user.givenName}`
                : 'unknown';
            const email = user ? `${user.email}` : 'unknown';
            const tenantName = () => {
                return r.tenantName;
            };

            const row = [
                r.projectName,
                r.aiAnalysisTypeNameJa,
                r.folderName,
                r.status,
                reformat(r.createdAt, 'yyyy/MM/dd HH:mm'),
                reformat(r.updatedAt, 'yyyy/MM/dd HH:mm'),
                difMinutes(r.createdAt, r.updatedAt),
                r.aiRunTime ? Math.ceil(r.aiRunTime / 1000 / 60) : '',
                userName,
                email,
            ];
            if (isSuperAdmin()) {
                row.unshift(tenantName());
            }
            return row;
        });
        const header = [
            'プロジェクト名',
            'AI名',
            'フォルダ名',
            'ステータス',
            '作成日時',
            '更新日時',
            '実効時間(分)',
            '推論時間(分)',
            '実行ユーザー名',
            'メールアドレス',
        ];
        if (isSuperAdmin()) {
            header.unshift('テナント名');
        }
        csvData.unshift(header);
        const csvContent =
            '\ufeff' +
            csvData
                .map((row) => {
                    return row
                        .map((col) => {
                            return (
                                '"' +
                                (typeof col == 'string'
                                    ? col.replace(/\"/g, '""')
                                    : col) +
                                '"'
                            );
                        })
                        .join(',');
                })
                .join('\n');

        downloadText('task_log.csv', csvContent, 'text/csv');
    };

    const getTask = async (offset: number) => {
        if (!offset) {
            setTasks([]);
        }
        if (!start) {
            return;
        }
        const data = await getAsync(
            projectApi.get.summary(start, end, offset, GET_TASK_LIMIT)
        );
        if (data.data.length) {
            setTasks((prev) => {
                const newList = [...prev, ...data.data];
                return newList;
            });
            if (data.data.length >= GET_TASK_LIMIT) {
                await getTask(data.pagination.nextOffset);
            }
        }
    };

    React.useEffect(() => {
        if (isSuperAdmin()) {
            getTenantsForSuperAdmin()
                .then((resp) => {
                    setTenants(resp.data);
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }, []);
    React.useEffect(() => {
        if (!start) {
            return;
        }
        setLoading(true);
        getTask(0)
            .catch((err) => {
                console.error(err);
                setErrors((errors) => [
                    ...errors,
                    '実行履歴の取得に失敗しました',
                ]);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [start]);

    if (!userInfo) return <Loading />;
    if (isSuperAdmin() && !tenants) {
        return <Loading />;
    }
    ///if (error) return <NotFound />;
    return (
        <div className="app-main log">
            <h2 className="header-name">
                {loading && <SpinnerIcon />} プロジェクトログ
            </h2>
            <TenantStorageStatus />
            <div className="tab">
                {[0, 1, 2].map((m) => (
                    <Button
                        onClick={() => setMonth(m)}
                        disabled={loading}
                        key={m}
                        className={month == m ? 'active' : ''}
                    >
                        <i
                            className={
                                month == m
                                    ? 'fa-regular fa-circle-dot'
                                    : 'fa-regular fa-circle'
                            }
                            style={{ marginRight: '.5rem' }}
                        />
                        {getYearMonthString(m)}
                    </Button>
                ))}
                {!loading && !!tasks.length ? (
                    <div className="download">
                        <IconButton
                            icon="fa-solid fa-download"
                            onClick={() => download()}
                        >
                            エクスポート
                        </IconButton>
                    </div>
                ) : (
                    ''
                )}
            </div>
            {month >= 0 && (
                <TaskLogs
                    tenants={tenants}
                    tasks={tasks ?? null}
                    loading={loading}
                />
            )}
        </div>
    );
};
