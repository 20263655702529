import React, { useCallback, RefObject, useEffect } from 'react';
import { formatVideoTime } from '../../utils/formatVideoTime';
import { Button } from '../atomic/buttons';
import { Icons } from '../atomic/icons';
import { APIError, useGet, useMutation } from '../../hooks/useApi';
import { useErrors } from '../../hooks/useErrors';
import Player from 'video.js/dist/types/player';
import { useVideoScrubTime } from '../../hooks/useVideoScrubTime';
import { DialogBox } from '../atomic/dialog';
import { SimpleDialogProps, useDialog } from '../../hooks/useDialog';
import { useNavigateToItem } from '../atomic/links';
import { userApi } from '../../APIs/userApi';
import { FilesAPI } from '../../APIs/filesApi';
import { isVideo } from '../../models/FileModel';

export interface ReelScreenshotsProps {
    projectId: string;
    fileId: string;
    sourceFileId: string;
    folderId: string;
    video: RefObject<Player | null>;
    readonly: boolean;
}

const GotoUploadDialog = (opts: SimpleDialogProps) => (
    <DialogBox title="確認" {...opts}>
        アップロードした画像のページを開きますか？
    </DialogBox>
);

const GotoNeighborDialog = (opts: SimpleDialogProps) => (
    <DialogBox title="確認" {...opts}>
        隣接するフレームが既にアップロードされています。
        <br />
        アップロード済みの画像のページを開きますか？
    </DialogBox>
);

interface FileLinkContext {
    fileId: string;
    sourceId: string;
}

const useGotoFileDialog = (dialog: React.FC<SimpleDialogProps>) => {
    const navigateToItem = useNavigateToItem();
    return useDialog<{}, void, FileLinkContext>(
        dialog,
        ({ fileId, sourceId }) => ({
            options: {},
            async onCommit() {
                navigateToItem({ fileId, sourceId });
            },
        }),
        [navigateToItem]
    );
};

export type ReelScreenshotButtonProps = {
    folderId: string;
    sourceId: string;
    video: RefObject<Player | null>;
};
export const ReelScreenshotButton = ({
    folderId,
    sourceId,
    video,
}: ReelScreenshotButtonProps) => {
    const source = useGet(FilesAPI.item(sourceId));
    const { appendError } = useErrors();
    const scrubTime = useVideoScrubTime(video);
    const upload = useMutation(FilesAPI.mutate.startVideoSlice);
    const gotoUpload = useGotoFileDialog(GotoUploadDialog);
    const gotoNeighbor = useGotoFileDialog(GotoNeighborDialog);
    const itemPermission = useGet(userApi.get.itemPermission(folderId));
    useEffect(() => {
        if (upload.error instanceof APIError && upload.error.status === 409) {
            const fileId = upload.error.data?.fileId;
            if (!fileId) return;
            gotoNeighbor.show({ fileId, sourceId });
        } else {
            if (upload?.error) {
                appendError(
                    upload.error.message || 'アップロードに失敗しました'
                );
            }
        }
    }, [upload.error]);
    const addScreenshot = useCallback(() => {
        const currVideo = video.current;
        if (!currVideo) return;
        if (!source.data) return;
        const videoTimeSeconds = ((scrubTime ?? 0) * 1000) | 0;

        upload.send({
            fileId: sourceId,
            videoTimeSeconds,
        });
    }, [scrubTime, sourceId, source.data]);
    useEffect(() => {
        if (!upload.lastSendSuccess) return;
        FilesAPI.frameIds({ folderId, sourceId }).mutate();
        if (upload.error) return;
        const data = upload.data;
        if (!data) return;
        gotoUpload.show({ fileId: data.fileId, sourceId });
    }, [upload.lastSendSuccess, upload.data]);
    if (
        !isVideo(source.data?.data) ||
        !source.data ||
        !itemPermission.data?.data.write
    ) {
        return <></>;
    }
    return (
        <div className="reel--screenshots">
            {upload.isSending ? (
                <Button disabled icon={Icons.Add}>
                    アップロード中
                </Button>
            ) : (
                <Button onClick={addScreenshot} icon={Icons.Add}>
                    <i className="fa-solid fa-stopwatch"></i>
                    {formatVideoTime(scrubTime ?? 0)}
                </Button>
            )}
            {gotoUpload.dialog}
            {gotoNeighbor.dialog}
        </div>
    );
};
