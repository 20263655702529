import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { projectApi, eachProjectFiles } from '../../../APIs/projectApi';
import { useGet } from '../../../hooks/useApi';
import { ProjectModel } from '../../../models/ProjectModel';
import { AiTypes } from './AiTypes';
import { Link } from 'react-router-dom';
import { FolderIcon, SpinnerIcon } from '../../atomic/icons';
import { Map } from './Map';
import { LatLng } from 'leaflet';
import { Loading } from '../../atomic/effects';
import { ProjectUpdatesModelEntry } from '../../../models/ProjectUpdateModel';
import { fixPHPArrayFilter } from '../../../utils/fixPHPArrayFilter';
import { Tag } from '../../../utils/ServerClientModel';
import classNames from 'classnames';
import { ErrorContext } from '../../../contexts';
import { ErrorDisplay, NotFound } from '../../Errors';
import { FileModelMetadata } from '../../../models/FileModel';
import { Queue } from '../../../utils/queue';
import { isAbortError } from '../../../utils/abort';
import { userApi } from '../../../APIs/userApi';
import { getRoundedLocation, zoomToRound } from '../utils';
import { FilesAPI } from '../../../APIs/filesApi';

interface PointProject {
    projectId: string;
    types: string[];
    tags: Tag[];
    aiAnalysisTypeId: string;
    show?: boolean;
}

interface PointFile {
    show: boolean;
    fileId: string;
    folderId: string;
    fileName: string;
    url: string;
    thumbUrl: string;
    metadata: FileModelMetadata;
    position: LatLng;
    projects: PointProject[];
}

interface Point {
    key: string;
    position: LatLng;
    show: boolean;
    files: PointFile[];
}

interface Points {
    [pointId: string]: Point;
}

interface Filter {
    [key: string]: boolean;
}
export const MapProjects: React.FC = () => {
    const defaultZoom = 16;
    const [loading, setLoading] = React.useState<boolean>(true);
    const [loadFiles, setLoadFiles] = React.useState<boolean>(false);
    const [center, setCenter] = React.useState<LatLng>(
        new LatLng(34.699, 135.5)
    );
    const [points, setPoints] = React.useState<Points>({});
    const [filter, setFilter] = React.useState<Filter>({});
    const [zoom, setZoom] = React.useState<number>(defaultZoom);

    const refFilter = React.useRef(filter);
    const refZoom = React.useRef(zoom);

    const [projects, setProjects] = React.useState<ProjectModel[] | null>(null);
    const { setErrors } = React.useContext(ErrorContext);

    const { folderId } = useParams();

    const { data: folder } = useGet(folderId ? FilesAPI.item(folderId) : null, {
        keepPreviousData: true,
    });

    // 子孫も含めたフォルダーのプロジェクトを取得
    const folderProjects = useGet(
        folderId ? projectApi.get.folderProjects(folderId, true) : null
    );
    const { data: getProjects, error, isLoading } = folderProjects;

    useEffect(() => {
        const _projects = getProjects?.data?.map((project: ProjectModel) => {
            return project;
        });
        if (_projects) {
            setProjects(_projects);
        }
        setLoading(false);
    }, [getProjects?.data]);

    const getFilterKey = (aiAnalysisTypeId: string, type: number) =>
        `${aiAnalysisTypeId}_${type}`;

    const onLabelTypeChange = React.useCallback(
        (aiAnalysisTypeId: string, type: number, checked: boolean) => {
            setFilter((prevState) => {
                const nextState = { ...prevState };
                nextState[getFilterKey(aiAnalysisTypeId, type)] = checked;
                return nextState;
            });
        },
        []
    );

    const updatePointsByFilter = (points: Points, filter: Filter) => {
        for (const key in points) {
            const point = points[key];
            //対象のファイルを特定
            point.files.map((file, fileIndex) => {
                // プロジェクトループ
                file.projects.forEach(({ types }, projectIndex) => {
                    file.projects[projectIndex].show = types.some(
                        (key) => filter[key]
                    );
                });
                // ファイルが表示対象かチェック
                point.files[fileIndex].show = point.files[
                    fileIndex
                ].projects.some(({ show }) => show);
            });

            // 表示対象のファイルがあるか？
            points[key].show = point.files.some(({ show }) => show);
        }
        return points;
    };
    useEffect(() => {
        refFilter.current = filter;
        setPoints((prevState) =>
            updatePointsByFilter(JSON.parse(JSON.stringify(prevState)), filter)
        );
    }, [filter]);

    const addFile = (
        points: Points,
        show: boolean,
        file: Omit<PointFile, 'position' | 'show'>,
        round: number
    ) => {
        const location = file.metadata.location!;
        const position = getRoundedLocation(
            location.latitude,
            location.longitude,
            round
        );
        const key: string = position.toString();
        const pointFile: PointFile = { ...file, show, position };

        if (points[key]) {
            // 同一ファイルはまとめる
            const sameFiles = points[key].files.filter(
                (file) => file.fileId === pointFile.fileId
            );
            if (sameFiles.length) {
                sameFiles.forEach((file, index) => {
                    file.projects.push(pointFile.projects[0]);
                });
            } else {
                points[key].files.push(pointFile);
            }
            if (show) {
                points[key].show = show;
            }
        } else {
            const position = new LatLng(location.latitude, location.longitude);
            points[key] = {
                key,
                position,
                show,
                files: [pointFile],
            };
        }
    };

    const updatePositionsByLocation = (points: Points) => {
        for (let key in points) {
            let lat = 0;
            let lng = 0;
            let count = 0;
            points[key].files.forEach(({ metadata }) => {
                const location = metadata?.location;
                if (!location) {
                    return;
                }
                lat += location.latitude;
                lng += location.longitude;
                count++;
            });
            if (count) {
                points[key].position = new LatLng(lat / count, lng / count);
            }
        }
    };

    const projectFileHandle = (
        project: ProjectModel,
        { item, update }: ProjectUpdatesModelEntry
    ) => {
        if (!update) return;
        const { file } = item;
        if (!file.metadata.location) {
            // 位置情報がない場合は処理を実施しない
            return;
        }
        const { compiled } = update;
        const tmpTagTypes = fixPHPArrayFilter(compiled.fileMeta.tags).map(
            (tag) => tag.type
        );
        const tagTypes = tmpTagTypes.filter(
            (tag, index) => tmpTagTypes.indexOf(tag) === index
        );
        if (!tagTypes.length) {
            return;
        }
        const filter = refFilter.current;
        const zoom = refZoom.current;
        const round = zoomToRound(zoom);
        const aiAnalysisTypeId =
            project.aiAnalysisTypeId ?? project.template.aiAnalysisTypeId;
        const types = tagTypes.map((type) =>
            getFilterKey(aiAnalysisTypeId, type)
        );
        setPoints((p) => {
            const _points = { ...p };
            addFile(
                _points,
                types.some((key) => filter[key]),
                {
                    fileId: file.fileId,
                    folderId: file.folderId,
                    fileName: file.fileName,
                    url: file.url,
                    thumbUrl: file.thumbUrl,
                    metadata: file.metadata,
                    projects: [
                        {
                            projectId: project.projectId,
                            types: types,
                            tags: fixPHPArrayFilter(compiled.fileMeta.tags),
                            aiAnalysisTypeId,
                        },
                    ],
                },
                round
            );
            updatePositionsByLocation(_points);
            return _points;
        });
    };

    useEffect(() => {
        if (projects) {
            setLoadFiles(true);
            const controller = new AbortController();
            const queue = new Queue({
                concurrency: 6,
                signal: controller.signal,
            });
            for (const project of projects) {
                queue.add(() =>
                    eachProjectFiles(
                        project.projectId,
                        { points: false, queue },
                        projectFileHandle
                    )
                );
            }
            queue
                .finish()
                .catch((err) => {
                    if (isAbortError(err)) return;
                    console.warn(err);
                    setErrors((errors) => [
                        ...errors,
                        err.message || 'ファイル情報の取得に失敗しました',
                    ]);
                })
                .finally(() => {
                    if (controller.signal.aborted) return;
                    setLoadFiles(false);
                });
            return () => {
                setLoadFiles(false);
                controller.abort();
            };
        }
    }, [projects]);

    useEffect(() => {
        folderProjects.mutate();
    }, []);

    useEffect(() => {
        refZoom.current = zoom;
        setPoints((points) => {
            const newPoints = {};
            const round: number = zoomToRound(zoom);
            for (let key in points) {
                const point = points[key];
                point.files.forEach((file) => {
                    addFile(newPoints, file.show, file, round);
                });
            }
            updatePositionsByLocation(newPoints);
            for (let key in newPoints) {
                newPoints[key].show = newPoints[key].files.some(
                    ({ show }) => show
                );
            }
            return newPoints;
        });
    }, [zoom]);

    const { data: accessItemData } = useGet(
        folderId ? userApi.get.itemPermission(folderId) : null
    );

    if (accessItemData?.data.showAiResult == 0) {
        return <NotFound />;
    }
    if (loading || !folder?.data || !projects || !accessItemData) {
        return <Loading />;
    }

    if (error) {
        return <ErrorDisplay error={error} />;
    }

    return (
        <div className="app-map-main">
            <div className="context">
                <Map
                    points={points}
                    center={center}
                    onChangeZoom={setZoom}
                    defaultZoom={defaultZoom}
                />
            </div>
            <div className="sidebar">
                <div className={classNames('sidebar-header')}>
                    {!loadFiles && <FolderIcon />}
                    {loadFiles && <SpinnerIcon />}
                    {folder.data && (
                        <Link to={`/app/folderlist/${folder.data.folderId}`}>
                            {folder.data.name}
                        </Link>
                    )}
                </div>
                <AiTypes
                    onLabelTypeChange={onLabelTypeChange}
                    projects={projects}
                    setPoints={setPoints}
                    points={points}
                ></AiTypes>
            </div>
        </div>
    );
};
