import React, { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { FolderViewTypes } from './consts';
import { Breadcrumbs } from './Breadcrumbs';

import { IconButton, ButtonGroup } from '../atomic/buttons';
import { Icon } from '../atomic/icons';
import { TextField } from '../atomic/forms';
import { useAuth } from '../../contexts/AuthContext';
import { userApi } from '../../APIs/userApi';
import { FolderCopyController } from './EditFolderDialog';
import { CopyFoldersModel, FolderViewCheckModel } from '../FolderView';
import { useGet } from '../../hooks/useApi';

export interface FolderControlProp {
    viewType: FolderViewTypes;
    currentFolder: any;
    onViewChange: (event: MouseEvent) => void;
    onClickAddFolder: () => void;
    onClickUpload: () => void;
    onClickDelete: () => void;
    onClickAddProject: () => void;
    onClickMapIcon: () => void;
    onClickFolderUpload: () => void;
    onFolderUploaded: () => void;
    checkList: FolderViewCheckModel[];
    hasCollaborator: boolean;
    copyFolders: CopyFoldersModel | null;
    setCopyFolders: (state: CopyFoldersModel | null) => void;
}
export const FolderControl = (props: FolderControlProp) => {
    const {
        viewType,
        currentFolder,
        onViewChange,
        onClickAddFolder,
        onClickUpload,
        onClickDelete,
        onClickAddProject,
        onClickMapIcon,
        onClickFolderUpload,
        onFolderUploaded,
        checkList = [],
        hasCollaborator,
        copyFolders,
        setCopyFolders,
    } = props;
    const { folderId, parentFolderId } = currentFolder;
    const parentPath = `/app/folderlist/${parentFolderId || ''}`;
    const navigate = useNavigate();
    const [searchWords, setSearchWords] = React.useState('');
    const [searchLinkVisible, showSearchLink] = React.useState(false);
    const navigateToSearch = () => {
        const _searchWords = searchWords.trim();
        const query = _searchWords.length > 0 ? `?words=${_searchWords}` : '';
        navigate(`/app/foldersearch${query}`);
    };
    const { isExternalUser } = useAuth();

    const { data: accessItemData } = useGet(
        folderId ? userApi.get.itemPermission(folderId) : null
    );

    const isWrite: boolean = React.useMemo(() => {
        if (isExternalUser() && folderId == null) {
            return false;
        }
        if (folderId) {
            return !!accessItemData?.data.write;
        }
        return true;
    }, [accessItemData, folderId]);

    return (
        <div className="folder-controls">
            <div className="links">
                {folderId && (
                    <>
                        <Icon icon="fa-solid fa-folder-tree" />
                        <Breadcrumbs folder={currentFolder} />
                    </>
                )}
            </div>
            {folderId && (
                <div className="view-toggle">
                    <ButtonGroup value={viewType} onChange={onViewChange}>
                        <IconButton
                            icon="fa-solid fa-table-cells-large"
                            value={FolderViewTypes.THUMB}
                        >
                            サムネイル表示
                        </IconButton>
                        <IconButton
                            icon="fa-solid fa-list"
                            value={FolderViewTypes.LIST}
                        >
                            リスト表示
                        </IconButton>
                    </ButtonGroup>
                </div>
            )}
            <div className="section right">
                <div className="buttons">
                    {isWrite && (
                        <>
                            <IconButton
                                icon="fa-solid fa-folder-plus"
                                onClick={onClickAddFolder}
                            >
                                フォルダを追加
                            </IconButton>

                            {viewType === FolderViewTypes.LIST && (
                                <IconButton
                                    icon="fa-solid fa-trash-can"
                                    disabled={checkList.length === 0}
                                    onClick={onClickDelete}
                                >
                                    削除
                                </IconButton>
                            )}
                            <FolderCopyController
                                viewType={viewType}
                                folderId={currentFolder.folderId}
                                checkList={checkList}
                                breadcrumbs={currentFolder.breadcrumbs}
                                onDone={onFolderUploaded}
                                hasCollaborator={hasCollaborator}
                                copyFolders={copyFolders}
                                setCopyFolders={setCopyFolders}
                            />
                        </>
                    )}

                    {currentFolder.folderId && (
                        <>
                            {isWrite && (
                                <>
                                    <IconButton
                                        icon="fa-solid fa-file-arrow-up"
                                        onClick={onClickUpload}
                                    >
                                        ファイルのアップロード
                                    </IconButton>
                                    <IconButton
                                        icon="fa-solid fa-arrow-up-from-bracket"
                                        onClick={onClickFolderUpload}
                                    >
                                        フォルダのアップロード
                                    </IconButton>
                                </>
                            )}
                            {!isExternalUser() && (
                                <>
                                    <IconButton
                                        icon="fa-solid fa-briefcase"
                                        onClick={onClickAddProject}
                                    >
                                        プロジェクトを追加
                                    </IconButton>
                                </>
                            )}
                            {accessItemData?.data.showAiResult == 1 && (
                                <IconButton
                                    icon="fa-solid fa-map"
                                    onClick={onClickMapIcon}
                                >
                                    マップ表示
                                </IconButton>
                            )}
                        </>
                    )}
                </div>
                <div className="search-area">
                    <TextField
                        placeholder="&#xF002;"
                        onEnter={navigateToSearch}
                        onChange={(ev) => setSearchWords(ev.target.value)}
                        onFocus={() => showSearchLink(true)}
                        onBlur={() =>
                            setTimeout(() => {
                                showSearchLink(false);
                            }, 100)
                        }
                        value={searchWords}
                    />
                    {searchLinkVisible && (
                        <div className="search-btn" onClick={navigateToSearch}>
                            <i className="fa-solid fa-magnifying-glass" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
