import React, { useEffect, useMemo } from 'react';
import { Button } from '../atomic/buttons';
import {
    SelectField,
    SelectFieldOption,
    SelectFieldOptionGroup,
} from '../atomic/forms';
import { Icons } from '../atomic/icons';
import { useGet } from '../../hooks/useApi';
import { formatVideoFileTime } from '../../utils/formatVideoFileTime';
import { useNavigateToItem } from '../atomic/links';
import { useFileType } from '../../hooks/useFileType';
import { FilesAPI } from '../../APIs/filesApi';
import {
    DeleteFilesFileInfo,
    useFileDeleteDialog,
} from '../folderview/DeleteFilesDialog';

const limit = 10;

export interface ReelFrameMenuProps {
    fileId: string;
    folderId: string;
    sourceId: string | undefined;
}

export const ReelFrameMenu = ({
    fileId,
    folderId,
    sourceId: inputSourceId,
}: ReelFrameMenuProps) => {
    const file = useGet(fileId ? FilesAPI.item(fileId) : null);
    const sourceFile = useGet(
        inputSourceId ? FilesAPI.item(inputSourceId) : null
    );
    const { isVideo } = useFileType(sourceFile.data?.data ?? file.data?.data);
    const sourceId = inputSourceId ?? (isVideo ? fileId : null);
    const fileIds = useGet(
        sourceId ? FilesAPI.frameIds({ folderId, sourceId }) : null
    );
    const [index, page, maxPage, offset, total] = useMemo(() => {
        if (!fileIds.data) return [-2, null, 0, 0, 0];
        const ids = fileIds.data.data;
        const index = ids.indexOf(fileId);
        const page = (index / limit) | 0;
        return [
            index,
            page,
            Math.ceil(ids.length / limit),
            page * limit,
            ids.length,
        ];
    }, [fileIds.data, fileId]);
    const pageFiles = useGet(
        offset !== null && sourceId
            ? FilesAPI.pagedFrames({
                  parentFolderId: folderId,
                  parent_file_id: sourceId,
                  limit,
                  offset,
              })
            : null
    );
    const pageIds =
        fileIds.data?.data.slice(offset ?? 0, (offset ?? 0) + limit) ?? [];
    const pageIdsStr = pageIds.join(',');
    useEffect(() => {
        pageFiles.mutate();
    }, [pageIdsStr]);
    const navigateToItem = useNavigateToItem();
    const gotoSource = useMemo(() => {
        if (!sourceId) return;
        if (sourceId === fileId) return;
        return () =>
            navigateToItem({
                fileId: sourceId,
                videoTimeSeconds: file.data?.data.videoTimeSeconds ?? undefined,
            });
    }, [fileId, sourceId, file]);
    const [next, prev, nextPage, prevPage] = useMemo(() => {
        if (!fileIds.data) return [];
        if (!sourceId) return [];
        const ids = fileIds.data.data;
        const nextFile: string | undefined =
            index < 0 ? ids[0] : ids[index + 1];
        const prevFile: string | undefined =
            index < 0 ? undefined : ids[index - 1] ?? sourceId;
        const next = nextFile
            ? () => navigateToItem({ fileId: nextFile, sourceId })
            : undefined;
        const prev = prevFile
            ? () => navigateToItem({ fileId: prevFile, sourceId })
            : undefined;
        const prevPage =
            index < 0 ? null : offset !== undefined ? ids[offset - 1] : null;
        const nextPage =
            index < 0
                ? ids[limit]
                : offset !== undefined
                ? ids[offset + limit]
                : null;
        return [next, prev, nextPage, prevPage];
    }, [fileIds.data?.data, index, offset]);
    const deleteDialog = useFileDeleteDialog(
        {
            onPreDelete() {
                if (next) next();
                else if (prev) prev();
                else gotoSource?.();
            },
        },
        [next, prev, gotoSource]
    );
    const selectOptions = useMemo(() => {
        const files = pageFiles.data?.data;
        if (!sourceId) return;
        const result: Array<SelectFieldOptionGroup | SelectFieldOption> = [];
        const pages: SelectFieldOption[] = pageIds.map((fileId) => {
            const file = files?.find((file) => file.fileId === fileId);
            return {
                value: fileId,
                text: file ? `⏱️ ${formatVideoFileTime(file)}` : ' …',
            };
        });
        result.push({
            value: sourceId,
        });
        result.push({
            label: `ページ ${(page ?? 0) + 1} / ${maxPage} (${total}スライス)`,
            options: pages,
        });
        result.push({
            text: '前のページ',
            value: prevPage ?? '',
            disabled: !prevPage,
        });
        result.push({
            text: '次のページ',
            value: nextPage ?? '',
            disabled: !nextPage,
        });
        return result;
    }, [
        pageIdsStr,
        pageFiles.data?.data,
        page,
        prevPage,
        nextPage,
        maxPage,
        total,
    ]);
    if (!sourceId) {
        return <></>;
    }
    const time = file.data ? formatVideoFileTime(file.data.data) : '??:??';
    const fileInfo: DeleteFilesFileInfo = {
        id: fileId,
        isFile: true,
        name: `${sourceFile.data?.data.name ?? '...'} ⏰ ${time}`,
    };
    return (
        <div className="reel--frame-navigation">
            <Button icon={Icons.Left} disabled={!prev} onClick={prev} />
            {gotoSource && <Button icon={Icons.Return} onClick={gotoSource} />}
            {selectOptions ? (
                <SelectField
                    options={selectOptions}
                    value={fileId}
                    _onChange={(fileId: string) =>
                        navigateToItem({ fileId, sourceId })
                    }
                />
            ) : (
                <div className="reel--filelist--loading">...</div>
            )}
            {sourceId !== fileId && (
                <Button
                    icon={Icons.Delete}
                    onClick={() =>
                        deleteDialog.show({
                            parentId: folderId,
                            files: [fileInfo],
                        })
                    }
                />
            )}
            <Button icon={Icons.Right} disabled={!next} onClick={next} />
            {deleteDialog.dialog}
        </div>
    );
};
