import { HttpClient, BaseApiResponse, useFetch } from '../utils/HttpUilts';
import { UserModel } from '../models/UserModel';
import { UserOneModel } from '../models/UserModel';
import { createGet, createMutation, simpleGet } from '../hooks/useApi';
import { TaskModel } from '../models/TaskModel';
import { FolderModel, FolderPermission } from '../models/FolderModel';

export type UserRole = 'SuperAdmin' | 'Admin' | 'User' | 'External';

export interface InviteUserRequest {
    tenantId: string;
    email: string;
    role: UserRole;
}

export interface UpdateUserRequest {
    familyName: string;
    givenName: string;
    phoneNumber: string;
}
export const getUsers = (): Promise<BaseApiResponse<Array<UserModel>>> => {
    return HttpClient.get('/users');
};

export const getUser = (
    userId: string
): Promise<BaseApiResponse<UserOneModel>> => {
    return HttpClient.get('/user/' + encodeURIComponent(userId));
};

export const postUser = (userId: string): Promise<BaseApiResponse<void>> => {
    return HttpClient.post(`/user/${userId}`, {});
};

export const updateUser = (
    userId: string,
    data: UpdateUserRequest
): Promise<BaseApiResponse<void>> => {
    return HttpClient.patch(`/user/${userId}`, data);
};

export const removeUser = (
    userId: string
): Promise<BaseApiResponse<{ userId: string }>> => {
    return HttpClient.del(`/user/${userId}`);
};

export const inviteUser = (data: InviteUserRequest) => {
    return HttpClient.post('/user', data);
};

export const userTask = createGet<{ data: Array<TaskModel> }>(
    '/user/task',
    undefined,
    { refreshInterval: 5000 }
);

export interface UserKeyModel {
    apiKey: string;
    expireAt: string;
}
export type UserKeysModel = Array<{
    userId: string;
    apiKey: string;
    expireAt: string;
}>;

export interface GroupFolderModel {
    groupFolderId: string;
    groupId: string;
    folderId: string;
    folder: FolderModel;
}
export interface GroupUserModel {
    groupUserId: string;
    userId: string;
    groupId: string;
}

export interface GroupModel {
    groupId: string;
    tenantId: string;
    name: string;
    groupFolders: Array<GroupFolderModel>;
    groupUsers: Array<GroupUserModel>;
}

export type GroupForUserModel = Omit<GroupModel, 'groupFolders' | 'groupUsers'>;

export type GroupRequest = Pick<GroupModel, 'name'>;

export type GroupsModel = Array<GroupModel>;

export type GroupUserRequest = Pick<GroupUserModel, 'userId' | 'groupId'>;

export type GroupFolderRequest = Pick<GroupFolderModel, 'groupId' | 'folderId'>;

export const userApi = {
    get: {
        userTask,
        itemPermission: (itemId: string) =>
            simpleGet<FolderPermission>`/item/${itemId}/isAccess`,
        apiKey: simpleGet<UserKeyModel>`/user/apikey`,
        apiKeys: simpleGet<UserKeysModel>`/user/apikeys`,

        users: () => {
            return simpleGet<UserModel[]>`/users`;
        },
        groups: () => {
            return simpleGet<GroupsModel>`/admin/groups`;
        },
        groupsForUser: () => {
            return simpleGet<GroupForUserModel[]>`/groups`;
        },
        group: (groupId?: string) => {
            if (!groupId) return null;
            return simpleGet<GroupModel>`admin/group/${encodeURIComponent(
                groupId
            )}`;
        },
        groupUsers: (groupId: string) => {
            return simpleGet<
                Array<GroupUserModel>
            >`/admin/group/${encodeURIComponent(groupId)}/users`;
        },
        groupFolders: (groupId: string) => {
            return simpleGet<
                Array<GroupFolderModel>
            >`/admin/group/${encodeURIComponent(groupId)}/folders`;
        },
    },
    mutate: {
        createGroup: createMutation<
            GroupRequest,
            {
                data: GroupModel;
            }
        >(async ({ authenticated }, data, opts) =>
            authenticated.post('/admin/group', data, opts)
        ),
        updateGroup: (groupId: string) =>
            createMutation<
                GroupRequest,
                {
                    data: GroupModel;
                }
            >(async ({ authenticated }, data, opts) =>
                authenticated.patch(
                    `/admin/group/${encodeURIComponent(groupId)}`,
                    data,
                    opts
                )
            ),
        createGroupUser: () =>
            createMutation<
                GroupUserRequest,
                {
                    data: GroupUserModel;
                }
            >(async ({ authenticated }, data, opts) =>
                authenticated.post(`/admin/group/user`, data, opts)
            ),
        createGroupFolder: () =>
            createMutation<
                GroupFolderRequest,
                {
                    data: GroupFolderModel;
                }
            >(async ({ authenticated }, data, opts) =>
                authenticated.post(`/admin/group/folder`, data, opts)
            ),
    },
    delete: {
        apiKey: (key: string) =>
            HttpClient.del(`/user/apikey/${encodeURIComponent(key)}`),
        userApiKey: (userId: string) =>
            HttpClient.del(`/user/${encodeURIComponent(userId)}/apikeys`),
        group: (groupId: string) => {
            return HttpClient.del(
                `/admin/group/${encodeURIComponent(groupId)}`
            );
        },
        groupUser: (groupUserId: string) => {
            return HttpClient.del(
                `/admin/group/user/${encodeURIComponent(groupUserId)}`
            );
        },
        groupFolder: (groupFolderId: string) => {
            return HttpClient.del(
                `/admin/group/folder/${encodeURIComponent(groupFolderId)}`
            );
        },
    },
};
