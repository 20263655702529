const PREFIX = '__REEL_LAST_FILEID:';

export const saveLastFileId = (projectId: string, fileId: string | null) => {
    localStorage?.setItem(
        `${PREFIX}${projectId}`,
        JSON.stringify({
            fileId,
            expire: new Date().getTime() + 7 * 24 * 60 * 60 * 1000,
        })
    );
};
export const loadLastFileId = (projectId: string) => {
    try {
        const jsonText = localStorage.getItem(`${PREFIX}${projectId}`);
        if (jsonText) {
            const json = JSON.parse(jsonText);
            return json?.fileId || '';
        }
    } catch (err) {
        console.error(err);
    }
    return null;
};
export const clearReelHistory = () => {
    // 有効期限切れのセッション情報を削除する
    const keys: string[] = [];
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith(PREFIX)) {
            keys.push(key);
        }
    }
    keys.forEach((key) => {
        const nowTime = new Date().getTime();
        try {
            const jsonText = localStorage.getItem(key);
            if (jsonText) {
                const json = JSON.parse(jsonText);
                if (nowTime > json?.expire) {
                    localStorage.removeItem(key);
                }
            } else {
                localStorage.removeItem(key);
            }
        } catch (err) {
            console.error(err);
            localStorage.removeItem(key);
        }
    });
};
