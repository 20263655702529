import React, { ReactNode, useMemo, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator, translations } from '@aws-amplify/ui-react';
import { cognitoConstants } from './consts';
import {
    Link,
    Navigate,
    Outlet,
    RouterProvider,
    createBrowserRouter,
    useLocation,
} from 'react-router-dom';
import { SWRConfig } from 'swr';
import { ErrorContext } from './contexts';
import { Header } from './components/headerfooter/Header';
import { Footer } from './components/headerfooter/Footer';
import { Home } from './components/Home';
import {
    Project,
    RedirectToProject,
    RedirectToProjectOfFolder,
} from './components/Project';
import { FolderView } from './components/FolderView';
import { FolderSearch } from './components/FolderSearch';
import { Map } from './components/map/Map';
import { NotFound } from './components/Errors';
import { AdminTags } from './components/admin/AdminTags';
import { AdminTag } from './components/admin/AdminTag';
import { AdminTemplates } from './components/admin/AdminTemplates';
import { AdminTemplate } from './components/admin/AdminTemplate';
import { Profile } from './components/Profile';
import { UserView } from './components/UserView';
import { AdminUserEditForm } from './components/user/AdminUserEditForm';
import { SignUpUser } from './components/SignUpUser';
import { SuperAdminIndex } from './components/superadmin/index';
import { DeleteIcon } from './components/atomic/icons';
import { Login } from './components/Login';
import { I18n } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { AuthLayout } from './components/login/AuthLayout';
import { AuthProvider } from './contexts/AuthContext';
import { TenantProvider } from './contexts/TenantContext';
import { useSyncErrors } from './SyncProcess';
import { SuperAdminTenantIndex } from './components/superadmin/tenant';
import { MapProjects } from './components/map/project/MapProjects';
import { AdminKintone } from './components/admin/kintone/AdminKintone';
import { AdminKintoneEdit } from './components/admin/kintone/AdminKintoneEdit';
import { AdminKintoneApps } from './components/admin/kintone/AdminKintoneApps';
import { Config } from './components/Config';
import { Report } from './components/Report';
import { ToastProvider } from './components/atomic/toast';
import { matchRoutes } from 'react-router';
import { Developer } from './components/Developer';
import { ActivityLog } from './components/log/ActivityLog';
import { clearReelHistory } from './components/reel/ReelHistory';
import { AdminUserGroupList } from './components/user/AdminUserGroupList';

interface LayoutProps {
    children?: ReactNode;
    className: string;
}

const Error = (props) => {
    const { errors, setErrors } = React.useContext(ErrorContext);
    const location = useLocation();
    const route = useMemo(
        () =>
            (matchRoutes(router.routes, location) ?? [])
                .map(({ route: { path } }) => path)
                .filter(Boolean)
                .join('/'),
        [location, router.routes]
    );
    const removeErrors = React.useCallback(
        (index: number) => {
            const newErrors = [...errors];
            newErrors.splice(index, 1);
            setErrors(newErrors);
        },
        [errors]
    );

    React.useEffect(() => {
        setErrors([]);
    }, [route]);

    return errors && errors.length > 0 ? (
        <div className="app-errors">
            {errors.map((message: string, index: number) => (
                <div className="error" key={index}>
                    <div role="message">{message}</div>
                    <div role="button">
                        <Link
                            to="#"
                            onClick={() => removeErrors(index)}
                            title="削除"
                        >
                            <DeleteIcon />
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    ) : (
        <></>
    );
};

const Layout = ({ className, children }: LayoutProps) => {
    useSyncErrors();
    return (
        <div className={`app-outer ${className}`}>
            <Header />
            <Error />
            <Outlet />
            {children}
            <Footer />
        </div>
    );
};

Amplify.configure(cognitoConstants);
translations['ja']['The user is not authenticated'] =
    '認証コードが間違っています。もう一度正確な認証コードを入力してください。認証コードを再送信しました。お手数ですが、受信ボックスを確認してください。';
I18n.putVocabularies(translations);
I18n.setLanguage('ja');

// Reelの履歴削除
clearReelHistory();

const router = createBrowserRouter([
    {
        path: 'app',
        element: <AuthLayout />,
        children: [
            {
                element: <Layout className="app-fullscreen" />,
                children: [
                    {
                        path: 'project/:projectId',
                        element: <RedirectToProject />,
                    },
                    {
                        path: 'f/:folderId/p/:projectId',
                        element: <RedirectToProjectOfFolder />,
                    },
                    {
                        path: 'f/:folderId/p/:projectId/f/:fileId?/:sourceFileId?',
                        element: <Project />,
                    },
                    {
                        path: 'folder/map/:tagTypeId',
                        element: <Map />,
                    },
                    {
                        path: 'folder/map/:tagTypeId/:tagDetailId',
                        element: <Map />,
                    },
                    {
                        path: 'f/:folderId/map',
                        element: <MapProjects />,
                    },
                ],
            },
            {
                element: <Layout className="app-scrollable" />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="home" />,
                    },
                    {
                        path: 'home',
                        element: <Home />,
                    },
                    {
                        path: 'folderlist',
                        children: [
                            {
                                index: true,
                                element: <FolderView />,
                            },
                            {
                                path: ':folderId',
                                element: <FolderView />,
                            },
                        ],
                    },
                    {
                        path: 'foldersearch',
                        element: <FolderSearch />,
                    },
                    {
                        path: 'config',
                        element: <Config />,
                    },
                    {
                        path: 'report/:projectId',
                        element: <Report />,
                    },
                    {
                        path: 'admin',
                        element: <AuthLayout pageType="admin" />,
                        children: [
                            {
                                path: 'tags',
                                element: <AdminTags />,
                            },
                            {
                                path: 'tag/:tenantTagId',
                                element: <AdminTag />,
                            },
                            {
                                path: 'templates',
                                element: <AdminTemplates />,
                            },
                            {
                                path: 'template/:aiTemplateId',
                                element: <AdminTemplate />,
                            },
                            {
                                path: 'users',
                                element: <UserView />,
                            },
                            {
                                path: 'user/edit',
                                element: <AdminUserEditForm />,
                            },
                            {
                                path: 'user/group',
                                element: <AdminUserGroupList />,
                            },
                            {
                                path: 'kintone',
                                element: <AdminKintone />,
                            },
                            {
                                path: 'kintone/apps',
                                element: <AdminKintoneApps />,
                            },
                            {
                                path: 'kintone/app/:appId/edit',
                                element: <AdminKintoneEdit />,
                            },
                            {
                                path: 'logs',
                                children: [
                                    {
                                        path: 'projects',
                                        element: <ActivityLog />,
                                    },
                                ],
                            },
                            {
                                path: '*',
                                element: <NotFound />,
                            },
                        ],
                    },
                    {
                        path: 'superadmin',
                        element: <AuthLayout pageType="superadmin" />,
                        children: [
                            {
                                path: '',
                                index: true,
                                element: <SuperAdminIndex />,
                            },
                            {
                                path: 'tenants',
                                element: <SuperAdminTenantIndex />,
                            },
                            {
                                path: 'logs',
                                children: [
                                    {
                                        path: 'projects',
                                        element: <ActivityLog />,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'profile',
                        element: <Profile />,
                    },
                    {
                        path: 'developer',
                        element: <Developer />,
                    },
                    {
                        path: 'users/register',
                        element: <SignUpUser />,
                    },
                    {
                        path: '*',
                        element: <NotFound />,
                    },
                ],
            },
        ],
    },
    {
        path: 'app/login',
        element: <Login />,
    },
    {
        index: true,
        element: <Navigate to="app" />,
    },
    {
        path: '*',
        element: <NotFound />,
    },
]);

const AppRoutes = () => {
    const [errors, setErrors] = useState<Array<string>>([]);
    return (
        <ErrorContext.Provider value={{ errors, setErrors }}>
            <TenantProvider>
                <ToastProvider>
                    <RouterProvider router={router} />
                </ToastProvider>
            </TenantProvider>
        </ErrorContext.Provider>
    );
};

export const App = () => {
    return (
        <SWRConfig
            value={{
                // see https://swr.vercel.app/docs/api
                errorRetryInterval: 1000 * 10,
                errorRetryCount: 3,
                revalidateOnFocus: false,
            }}
        >
            <Authenticator.Provider>
                <AuthProvider>
                    <AppRoutes />
                </AuthProvider>
            </Authenticator.Provider>
        </SWRConfig>
    );
};
