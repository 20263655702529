import { HttpClient } from '../utils/HttpUilts';
import { LocalVideoConfig } from '../components/config/LocalConfig';
import { ItemModel } from '../models/ItemModel';

const BASE_PATH = '/folders';

export interface FetchFolderFileResource {
    fileId: string;
    sourceId: string | null;
    fileName: string;
    mimeType: string;
    url: string;
    videoTimeSeconds: number | null;
    createdUserId: string;
    updatedUserId: string;
    createdAt: string;
    updatedAt: string;
}

export interface FetchFolderTaskResource {
    state: 'SUCCESS' | 'ERROR' | 'RUNNING';
}

export interface FetchFolderProjectResource {
    projectId: string;
    name: string;
    aiAnalysisTypeId: string | null;
    aiTemplateId: string | null;
    runnable: boolean;
    options?: {
        frames: LocalVideoConfig | null;
    };
    task: FetchFolderTaskResource | null;
    createdUserId: string;
    createdAt: string;
}

export interface FetchFolderSubfolderResource {
    folderId: string;
    folderName: string;
    parentFolderId: string;
    isLocked: boolean;
    createdUserId: string;
    updatedUserId: string;
    createdAt: string;
    updatedAt: string;
}
export interface BreadcrumbResource {
    folderId: string;
    folderName: string;
}
export interface FetchFolderResource {
    folderId: string;
    folderName: string;
    breadcrumbs: BreadcrumbResource[];
    tags: Array<any>;
    parentFolderId: string | null;
    isLocked: boolean;
    projects: Array<FetchFolderProjectResource> | null;
    files: Array<FetchFolderFileResource>;
    createdUserId: string;
    updatedUserId: string;
    createdAt: string;
    updatedAt: string;
    subFolders: Array<FetchFolderSubfolderResource>;
}

export const FoldersAPI = {
    useSWR: (path: string = '') => HttpClient.useSWR(`${BASE_PATH}/${path}`),
    post: ({ parentFolderId, folderName, groupIds }) =>
        HttpClient.post(BASE_PATH, { parentFolderId, folderName, groupIds }),
    put: (
        folderId: string,
        {
            parentFolderId,
            folderName,
            groupIds,
        }: {
            parentFolderId: string | null;
            folderName: string;
            groupIds: Array<String> | undefined | null;
        }
    ) =>
        HttpClient.put('/folder/' + encodeURIComponent(folderId), {
            parentFolderId,
            folderName,
            groupIds,
        }),
    search: ({
        values,
        tenantTagIds,
        offset,
        limit,
    }: {
        values: string[];
        tenantTagIds: string[];
        offset: number;
        limit: number;
    }) => {
        return HttpClient.get(BASE_PATH, {
            values,
            tenantTagIds,
            offset,
            limit,
        });
    },

    users: {
        post: (
            folderId: string,
            userId: string,
            showTags: boolean,
            showAiResult: boolean,
            write: boolean
        ) => {
            return HttpClient.post(`/folder/${folderId}/user`, {
                userId: userId,
                showTags: showTags,
                showAiResult: showAiResult,
                write: write,
            });
        },
        delete: (folderId: string, userId: string) => {
            return HttpClient.del(`/folder/${folderId}/user/${userId}`);
        },
    },
};

export const getFoldersByType = (type: number) => {
    return HttpClient.get('/folders/type/' + encodeURIComponent(type));
};

export const getLocations = (folderId: string) => {
    return HttpClient.get(
        `/folder/${encodeURIComponent(folderId)}/files/locations`
    );
};

export const getKintoneAppFolders = (ids: Array<number>) => {
    const paramString = new URLSearchParams(
        ids.map((id) => ['ids[]', id.toString()])
    ).toString();
    return HttpClient.get(`/items/folders/kintone/?${paramString}`);
};
